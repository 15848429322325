import { useState, useEffect } from "react";
import CartIcon from "../icons/cart-icon";
import { useCart } from "@/contexts/cart/cart.context";
import { useUI } from "@/contexts/ui.context";
export default function CartButton() {
  const { openCart } = useUI();
  const [total, setTotal] = useState(0);
  const { items } = useCart();
  useEffect(() => {
    setTotal(items.length);
  }, [items]);
  function handleCartOpen() {
    return openCart();
  }
  return (
    <button
      className="relative flex items-center justify-center flex-shrink-0 h-auto transform focus:outline-none lg:mr-0 mr-2"
      onClick={handleCartOpen}
      aria-label="cart-button"
    >
      <CartIcon />
      <span className="cart-counter-badge flex items-center justify-center bg-heading text-white absolute -top-2.5 md:-top-3 lg:-top-3.5 rounded-full -right-2.5 xl:-right-3font-bold">
        {total}
      </span>
    </button>
  );
}
