import { gql } from "./graphql";
export const BLOG_FIELDS = gql`
  fragment BlogFields on ArticleEntity {
    id
    attributes {
      title
      author
      authorImage {
        data {
          attributes {
            url
            alternativeText
          }
        }
      }
      slug
      bannerImage {
        data {
          attributes {
            url
            alternativeText
            formats
          }
        }
      }
      thumbnail {
        data {
          attributes {
            url
            alternativeText
            formats
          }
        }
      }
      postContent
      publishedDate
      seo {
        metaTitle
        metaDescription
        metaImage {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
        metaSocial {
          description
        }
        keywords
        metaRobots
        structuredData
      }
    }
  }
`;
export const QUERY_BLOG = gql`
  ${BLOG_FIELDS}
  query getBlog($start: Int!, $limit: Int!, $sortBy: [String]) {
    articles(pagination: { start: $start, limit: $limit }, sort: $sortBy) {
      data {
        ...BlogFields
      }
    }
  }
`;
export const POST_BY_SLUG = gql`
  ${BLOG_FIELDS}
  query postBySlug($slug: String!) {
    article(slug: $slug) {
      data {
        ...BlogFields
      }
    }
  }
`;
