import Image from "next/image";
import Link from "next/link";
import cn from "classnames";
import { siteSettings } from "@/settings/site-settings";

const Logo = ({ className, position = "left", ...props }) => {
  return (
    <Link
      href={"/"}
      className={cn(
        "inline-flex focus:outline-none lg:w-52 lg:h-16 md:h-16 h-12 w-40 relative",
        className
      )}
      {...props}
    >
      <Image
        src={siteSettings.logo.url}
        alt={siteSettings.logo.alt}
        priority={true}
        fill
        sizes="(max-width: 768px) 160px, (max-width: 1200px) 224px, 224px"
        className={cn("object-contain", {
          "object-left": position === "left",
          "object-center": position === "center",
        })}
      />
    </Link>
  );
};

export default Logo;
