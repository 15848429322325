import { gql } from "./graphql";
export const PRODUCT_FIELDS = gql`
  fragment ProductFields on Product {
    name
    description
    onlyFewLeft
    expressDelivery
    slug
    outOfStock
    lowestPrice
    variants(pagination: { limit: 50 }) {
      id
      size
      salePrice
      compareAtPrice
      quantity
    }
    productType {
      data {
        attributes {
          name
        }
      }
    }
    images {
      data {
        attributes {
          url
          name
          alternativeText
          formats
        }
      }
    }
  }
`;
export const PRODUCT_VARIANTS = gql`
  fragment ProductVariants on ProductRelationResponseCollection {
    data {
      attributes {
        variants(pagination: { limit: 50 }) {
          id
          size
          salePrice
          compareAtPrice
          quantity
        }
      }
    }
  }
`;
export const COLLECTION_FIELDS = gql`
  fragment CollectionFields on Collection {
    name
    slug
    image {
      data {
        attributes {
          alternativeText
          url
          formats
        }
      }
    }
    seo {
      metaTitle
      metaDescription
      metaImage {
        data {
          attributes {
            url
            alternativeText
          }
        }
      }
      metaSocial {
        description
      }
      keywords
      metaRobots
      structuredData
      breadcrumbsStructuredData
    }
  }
`;
export const QUERY_ALL_COLLECTIONS = gql`
  ${COLLECTION_FIELDS}
  query getAllCollections {
    collections(sort: "name:asc", pagination: { limit: 10 }) {
      data {
        id
        attributes {
          ...CollectionFields
        }
      }
    }
  }
`;
export const QUERY_COLLECTION_BY_SLUG = gql`
  ${COLLECTION_FIELDS}
  query getCollectionBySlug($slug: String!) {
    collections(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          ...CollectionFields
        }
      }
    }
  }
`;
export const QUERY_COLLECTION_BY_SLUG_ALL_PRODUCTS = gql`
  query getCollectionBySlug($slug: String!) {
    collections(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          name
          slug
          image {
            data {
              attributes {
                url
              }
            }
          }
          parentCollection {
            data {
              attributes {
                name
                slug
              }
            }
          }
          seo {
            metaTitle
            metaDescription
            metaImage {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
          productSelector(
            pagination: { start: 0, limit: 5000 }
            sort: "order:asc"
          ) {
            order
            product {
              data {
                id
                attributes {
                  name
                  slug
                  outOfStock
                  lowestPrice
                  variants(pagination: { limit: 50 }) {
                    id
                    size
                    salePrice
                    compareAtPrice
                  }
                  brands {
                    data {
                      attributes {
                        name
                      }
                    }
                  }
                  images {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_OUT_OF_STOCK_PRODUCTS_COLLECTION_BY_SLUG = gql`
  ${COLLECTION_FIELDS}
  ${PRODUCT_FIELDS}
  query getCollectionBySlug(
    $slug: String!
    $limit: Int!
    $start: Int!
    $filter: ProductFiltersInput
    $sortBy: [String]
  ) {
    collections(filters: { slug: { eq: $slug } }, pagination: { limit: 100 }) {
      data {
        id
        attributes {
          ...CollectionFields
          productSelector(
            pagination: { start: $start, limit: $limit }
            filters: { product: $filter }
            sort: $sortBy
          ) {
            order
            product {
              data {
                id
                attributes {
                  ...ProductFields
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_PRODUCTS_COLLECTION_BY_SLUG = gql`
  ${COLLECTION_FIELDS}
  ${PRODUCT_FIELDS}
  query getCollectionBySlug(
    $slug: String!
    $limit: Int!
    $start: Int!
    $filter: ProductFiltersInput
    $sortBy: [String]
  ) {
    collections(filters: { slug: { eq: $slug } }, pagination: { limit: 100 }) {
      data {
        id
        attributes {
          ...CollectionFields
          productSelector(
            pagination: { start: $start, limit: $limit }
            filters: { product: $filter }
            sort: $sortBy
          ) {
            order
            product {
              data {
                id
                attributes {
                  ...ProductFields
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_PAIR_WITH_PRODUCTS = gql`
  query getProductsToPair($limit: Int!, $filter: ProductFiltersInput) {
    products(pagination: { limit: $limit }, filters: $filter) {
      data {
        id
        attributes {
          name
          slug
          lowestPrice
          variants(pagination: { limit: 100 }) {
            id
            size
            salePrice
            compareAtPrice
            quantity
          }
          images {
            data {
              attributes {
                url
                alternativeText
                formats
              }
            }
          }
        }
      }
    }
  }
`;
