export function addItemWithQuantity(items, item, quantity) {
  if (quantity <= 0)
    throw new Error("cartQuantity can't be zero or less than zero");
  const existingItemIndex = items.findIndex(
    (existingItem) =>
      existingItem.slug === item.slug &&
      existingItem?.attributes?.size === item?.attributes?.size
  );

  if (existingItemIndex > -1) {
    const newItems = [...items];
    newItems[existingItemIndex].quantity += quantity;
    return newItems;
  }
  return [...items, { ...item, quantity }];
}

export function removeItemOrQuantity(items, slug, quantity, size) {
  return items.reduce((acc, item) => {
    if (item.slug === slug && item.attributes.size === size) {
      const newQuantity = item.quantity - quantity;

      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc];
    }
    return [...acc, item];
  }, []);
}
// Simple CRUD for Item
export function addItem(items, item) {
  return [...items, item];
}

export function getItem(items, slug) {
  return items.find((item) => item.slug === slug);
}

export function updateItem(items, slug, item) {
  return items.map((existingItem) =>
    existingItem.slug === slug ? { ...existingItem, ...item } : existingItem
  );
}

export function removeItem(items, slug, size) {
  return items.filter((existingItem) => {
    return !(
      existingItem.slug === slug && existingItem.attributes.size === size
    );
  });
}

export const calculateItemTotals = (items) =>
  items.map((item) => ({
    ...item,
    itemTotal: item.attributes.salePrice * item.quantity,
  }));

export const calculateTotal = (items) =>
  items.reduce(
    (total, item) => total + item.quantity * item.attributes.salePrice,
    0
  );

export const calculateTotalItems = (items) =>
  items.reduce((sum, item) => sum + item.quantity, 0);

export const calculateUniqueItems = (items) => items.length;
