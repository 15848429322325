import React from "react";
import CartDrawer from "./cart-drawer";
import MenuDrawer from "./menu-drawer";

const ManagedDrawer = () => {
  return (
    <>
      <CartDrawer />
      <MenuDrawer />
    </>
  );
};

export default ManagedDrawer;
