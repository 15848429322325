import React, { useEffect, useState } from "react";
import { AnimatePresence } from "@/lib/framer-motion";
import { useUI } from "@/contexts/ui.context";
import EmailPopupForm from "./email-popup-form";
export default function EmailPopup() {
  const { displayEmailPopup, openEmailPopup } = useUI();
  const [modalShown, setModalShown] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 1500;

      if (scrollPosition > scrollThreshold && !modalShown) {
        openEmailPopup();
        setModalShown(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [modalShown, openEmailPopup]);

  return (
    <AnimatePresence>{displayEmailPopup && <EmailPopupForm />}</AnimatePresence>
  );
}
