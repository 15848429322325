// Simple CRUD for Item
export function addItem(items, item) {
  return [...items, item];
}

export function getItem(items, slug) {
  return items.find((item) => item.slug === slug);
}

export function updateItem(items, slug, item) {
  return items.map((existingItem) =>
    existingItem.slug === slug ? { ...existingItem, ...item } : existingItem
  );
}

export function removeItem(items, slug) {
  return items.filter((existingItem) => existingItem.slug !== slug);
}

export const calculateItemTotals = (items) =>
  items.map((item) => ({
    ...item,
    itemTotal: item.attributes.salePrice * item.quantity,
  }));

export const calculateTotal = (items) =>
  items.reduce(
    (total, item) => total + item.quantity * item.attributes.salePrice,
    0
  );

export const calculateTotalItems = (items) =>
  items.reduce((sum, item) => sum + item.quantity, 0);

export const calculateUniqueItems = (items) => items.length;
