import React from "react";

export default function CloseIconLight({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M21 12.5H3v-1h18v1Z"></path>
      <path d="M12.5 3v18h-1V3h1Z"></path>
    </svg>
  );
}
