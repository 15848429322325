import { gql } from "./graphql";
export const AUTHOR_FIELDS = gql`
  fragment AuthorFields on AuthorEntity {
    id
    attributes {
      name
      slug
      designation
      description
      linkedInLink
      twitterLink
      image {
        data {
          attributes {
            url
            alternativeText
          }
        }
      }
      seo {
        metaTitle
        metaDescription
        metaImage {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
      }
    }
  }
`;
export const QUERY_AUTHORS = gql`
  ${AUTHOR_FIELDS}
  query authors($start: Int!, $limit: Int!, $sortBy: [String]) {
    authors(pagination: { start: $start, limit: $limit }, sort: $sortBy) {
      data {
        ...AuthorFields
      }
    }
  }
`;

export const AUTHOR_BY_SLUG = gql`
  ${AUTHOR_FIELDS}
  query authorBySlug($slug: String!) {
    author(slug: $slug) {
      data {
        ...AuthorFields
      }
    }
  }
`;
