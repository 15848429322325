import { useRef, useEffect } from "react";
import { m, AnimatePresence } from "@/lib/framer-motion";
import useScrollLock from "@/utils/use-scroll-lock";
import cn from "classnames";
import CloseIcon from "@/components/icons/close-icon";
import { fadeInOut } from "@/utils/motion/fade-in-out";
import { zoomOutIn } from "@/utils/motion/zoom-out-in";
import { useUI } from "@/contexts/ui.context";
import useOnClickOutside from "@/utils/use-click-outside";

// variant based classes for modal root, container & close btn
const rootClasses = {
  center: "p-4 md:p-5",
  bottom: "p-5 pb-0",
};
const containerClasses = {
  center: "h-auto max-h-full top-1/2 -translate-y-1/2 rounded-lg",
  bottom: "h-full max-h-70vh bottom-0 rounded-tr-2xl rounded-tl-2xl",
};
const closeBtnClasses = {
  center: "-top-3 md:-top-4 -right-3 md:-right-4",
  bottom: "top-1/4 left-1/2 transform -translate-y-1/2 -translate-x-1/2",
};

export default function Modal({
  children,
  open,
  onClose,
  rootClassName,
  useBlurBackdrop,
  containerClassName,
  variant = "center",
}) {
  const { closeModal } = useUI();
  const modalRootRef = useRef();
  const modalInnerRef = useRef();
  useOnClickOutside(modalInnerRef, () => closeModal());
  useScrollLock(open);
  return (
    <AnimatePresence initial={false}>
      {open && (
        <m.div
          ref={modalRootRef}
          key="modal"
          initial="from"
          animate="to"
          exit="from"
          variants={fadeInOut(0.25)}
          className={cn(
            "modal-root fixed bg-black bg-opacity-70 inset-0 z-50",
            useBlurBackdrop && "backdrop-filter backdrop-blur-sm",
            rootClasses[variant],
            rootClassName
          )}
        >
          <m.div
            initial="from"
            animate="to"
            exit="from"
            variants={zoomOutIn()}
            className="relative w-full h-full mx-auto"
          >
            <div
              className={cn(
                "w-full lg:w-auto absolute left-1/2 transform -translate-x-1/2 shadow-xl",
                containerClasses[variant],
                containerClassName
              )}
            >
              <button
                onClick={onClose}
                aria-label="Close panel"
                className={cn(
                  "cursor-pointer fixed z-10 inline-flex items-center justify-center w-7 h-7 md:w-8 md:h-8 rounded-full bg-white shadow text-gray-600 transition duration-200 focus:outline-none focus:text-gray-800 focus:shadow-md hover:text-gray-800 hover:shadow-md",
                  closeBtnClasses[variant]
                )}
              >
                <CloseIcon className="w-6" />
              </button>
              <div
                ref={modalInnerRef}
                className="h-full overflow-y-auto rounded-lg"
                style={{ maxHeight: "calc(100vh - 100px)" }}
              >
                {children}
              </div>
            </div>
          </m.div>
        </m.div>
      )}
    </AnimatePresence>
  );
}
