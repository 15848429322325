import React, { useState, useEffect, useRef } from "react";

const FloatingInput = React.forwardRef(
  (
    { labelKey, name, errorKey, placeholderKey, type = "text", watch, ...rest },
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const value = watch(name);
    const inputRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
          if (!value) setFocused(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, [value]);

    const handleFocus = () => setFocused(true);
    const handleBlur = () => {
      if (!value) setFocused(false);
    };

    return (
      <div className="flex flex-col w-full gap-1">
        <div
          ref={inputRef}
          className={`autoNone relative w-full bg-transparent border-black ${
            focused || value ? "border-b-[2px]" : "border-b-[1px]"
          }`}
        >
          {labelKey && (
            <label
              htmlFor={name}
              className={`absolute left-0 transition-all duration-200 ease-in-out text-[#333] pointer-events-none ${
                focused || value
                  ? "-top-2 text-xs"
                  : "top-1/2 text-sm transform -translate-y-1/2"
              }`}
            >
              {labelKey}
            </label>
          )}
          {type === "textarea" ? (
            <textarea
              id={name}
              name={name}
              ref={ref}
              placeholder={placeholderKey}
              className="outline-none border-none bg-transparent w-full px-0 py-2 text-sm md:text-base"
              autoComplete="new-password"
              spellCheck="false"
              aria-invalid={errorKey ? "true" : "false"}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...rest}
            />
          ) : (
            <input
              id={name}
              name={name}
              type={type}
              ref={ref}
              placeholder={placeholderKey}
              className="outline-none border-none bg-transparent w-full px-0 py-2 text-sm md:text-base"
              autoComplete="new-password"
              spellCheck="false"
              aria-invalid={errorKey ? "true" : "false"}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...rest}
            />
          )}
        </div>
        {errorKey && <p className="mt-2 text-sm text-red">{errorKey}</p>}
      </div>
    );
  }
);

FloatingInput.displayName = "FloatingInput";
export default FloatingInput;
