import { gql } from "./graphql";
export const FAQ = gql`
  query getFAQ {
    faqs {
      data {
        attributes {
          question
          answer
        }
      }
    }
  }
`;
