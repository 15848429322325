import { gql } from "./graphql";

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(
      input: { identifier: $email, password: $password, provider: "local" }
    ) {
      jwt
      user {
        id
        email
        username
        confirmed
        blocked
        role {
          id
          name
          description
          type
        }
        name
      }
    }
  }
`;
export const ME = gql`
  query me {
    me {
      id
      username
      email
      username
    }
  }
`;
export const REGISTER = gql`
  mutation register(
    $username: String!
    $email: String!
    $password: String!
    $name: String!
  ) {
    register(
      input: {
        username: $username
        email: $email
        password: $password
        name: $name
      }
    ) {
      jwt
      user {
        username
        email
        name
      }
    }
  }
`;
export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $code: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      code: $code
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      user {
        username
        email
        confirmed
        name
      }
      jwt
    }
  }
`;
