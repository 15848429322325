import React, { useRef, useState, forwardRef } from "react";

const FileUpload = forwardRef(
  (
    {
      className = "block w-full",
      name,
      errorKey,
      type,
      placeholderKey,
      setValue,
      clearErrors,
      ...rest
    },
    ref
  ) => {
    const [fileName, setFileName] = useState("");

    const inputRef = useRef(null);

    const handleClick = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };

    const handleFileChange = (event) => {
      const files = event.target.files;
      setFileName(files[0]?.name);

      setValue(name, files);
      clearErrors(name);
    };

    return (
      <div className="w-full">
        <div
          onClick={handleClick}
          className="flex items-center w-full max-w-sm bg-white border-[0.5px] border-darkGrey/50 rounded-full lg:py-2 py-2 px-4 cursor-pointer"
        >
          <span className="text-darkGrey/50 text-sm flex-grow">
            {fileName || placeholderKey}
          </span>
          <div className="w-8 h-8 bg-[#e0ded8] rounded-full flex items-center justify-center shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-gray-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18v-1.5M7.5 12l4.5 4.5m0 0l4.5-4.5m-4.5 4.5V3"
              />
            </svg>
          </div>
        </div>
        <input
          id={name}
          name={name}
          type={type}
          ref={(el) => {
            inputRef.current = el;
            if (typeof ref === "function") {
              ref(el);
            } else if (ref) {
              ref.current = el;
            }
          }}
          onChange={handleFileChange}
          accept=".png,.jpg,.jpeg,.pdf,.txt,.doc,.docx"
          autoComplete="off"
          spellCheck="false"
          className="hidden"
          aria-invalid={errorKey ? "true" : "false"}
          {...rest}
        />
        {errorKey && <p className="my-2 text-xs text-red-500">{errorKey}</p>}
      </div>
    );
  }
);

FileUpload.displayName = "FileUpload";
export default FileUpload;
