import cn from "classnames";

const Scrollbar = ({ options, children, style, className, ...props }) => {
  return (
    <div className={cn("flex w-full overflow-y-auto", className)} {...props}>
      {children}
    </div>
  );
};

export default Scrollbar;
