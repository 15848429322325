import { gql } from "./graphql";
export const QUERY_GET_PROMO = gql`
  query getPromoCode($code: String!) {
    promoCode(code: $code) {
      data {
        id
        attributes {
          code
          redemptions
          validity
          discountAmount
          discountPercentage
          collections {
            data {
              attributes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_UPDATE_PROMO = gql`
  mutation updatePromoCode($redemptions: Int, $id: ID!) {
    updatePromoCode(id: $id, data: { redemptions: $redemptions }) {
      data {
        id
        attributes {
          code
          discountAmount
          discountPercentage
          redemptions
          validity
          collections {
            data {
              attributes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;
