import { gql } from "./graphql";
export const HOME_GRAILS_MINI_CAROUSEL = gql`
  query homeGrailsMiniCarousel {
    homeGrailsMiniCarousel {
      data {
        id
        attributes {
          carousel {
            id
            image {
              data {
                attributes {
                  name
                  alternativeText
                  caption
                  url
                  formats
                }
              }
            }
            mobileImage {
              data {
                attributes {
                  name
                  alternativeText
                  caption
                  url
                  formats
                }
              }
            }
            link
          }
        }
      }
    }
  }
`;
export const HOME_ACCESSORIES_CAROUSEL = gql`
  query accessoriesCarousel {
    homeAccessoriesCarousel {
      data {
        id
        attributes {
          carousel {
            id
            image {
              data {
                attributes {
                  name
                  alternativeText
                  caption
                  url
                  formats
                }
              }
            }
            mobileImage {
              data {
                attributes {
                  name
                  alternativeText
                  caption
                  url
                  formats
                }
              }
            }
            link
          }
        }
      }
    }
  }
`;

export const HOME_STREET_CAROUSEL = gql`
  query streetCarousel {
    homeStreetwearCarousel {
      data {
        id
        attributes {
          carousel {
            id
            image {
              data {
                attributes {
                  name
                  alternativeText
                  caption
                  url
                  formats
                }
              }
            }
            mobileImage {
              data {
                attributes {
                  name
                  alternativeText
                  caption
                  url
                  formats
                }
              }
            }
            link
          }
        }
      }
    }
  }
`;
