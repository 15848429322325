import { gql } from "./graphql";
export const QUERY_ALL_BRANDS_BY_COLLECTION = gql`
  query getBrands(
    $limit: Int!
    $filter: BrandFiltersInput
    $sortBy: [String]
    $slug: String
  ) {
    brands(sort: $sortBy, pagination: { limit: $limit }, filters: $filter) {
      data {
        id
        attributes {
          name
          products(
            pagination: { limit: 5000 }
            filters: { productCollections: { slug: { eq: $slug } } }
          ) {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
