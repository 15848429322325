import {
  m as mTag,
  AnimatePresence as AnimatePresenceTag,
  LazyMotion as LazyMotionTag,
  domAnimation as domAnimationTag,
} from "framer-motion";

export const m = mTag;
export const AnimatePresence = AnimatePresenceTag;
export const LazyMotion = LazyMotionTag;
export const domAnimation = domAnimationTag;
