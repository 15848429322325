import { gql } from "./graphql";
export const SEARCH_PRODUCTS = gql`
  query searchProducts($search: String, $limit: Int) {
    products(
      pagination: { limit: $limit }
      filters: {
        or: [
          { name: { containsi: $search } }
          { description: { containsi: $search } }
        ]
      }
    ) {
      data {
        attributes {
          name
          slug
          lowestPrice
          variants(pagination: { start: 0, limit: 1 }, sort: "salePrice:asc") {
            salePrice
          }
          images(pagination: { limit: 1, start: 0 }) {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
