import React, { useState } from "react";
import { m, AnimatePresence } from "framer-motion";
import CloseIcon from "../icons/close-icon";
import CloseIconLight from "../icons/close-icon-light";
export default function FaqDropdown({ children, initial = false, title }) {
  const [open, setOpen] = useState(initial ?? false);
  return (
    <div
      className={`last:mb-6 px-0 w-full group flex flex-col z-0 border-b-[1px] border-black overflow-hidden cursor-pointer`}
    >
      <div
        className="flex flex-row justify-between items-start py-5 h-auto w-full relative"
        onClick={() => setOpen(!open)}
      >
        <h3
          className={`!leading-tight transition-all duration-300 ease text-black font-light md:text-lg text-base max-w-[90%] md:max-w-full`}
        >
          {title}
        </h3>

        <CloseIconLight
          className={`shrink-0 md:w-6 md:h-6 w-5 h-5 transition-all text-body duration-400 ease transform mt-0.5 ${
            !open ? "rotate-0 translate-y-[1px]" : "rotate-45 translate-y-0"
          } relative`}
        />
      </div>
      <AnimatePresence initial={false}>
        {open && (
          <m.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: "auto", opacity: 1 },
              collapsed: { height: "0px", opacity: 0 },
            }}
            transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <m.div
              variants={{ collapsed: { opacity: 0.8 }, open: { opacity: 1 } }}
              transition={{ duration: 0.3 }}
              className="w-full pb-8 md:text-base text-sm [&>p>a]:text-blue [&>p>a]:underline [&>p>a]:hover:text-black"
            >
              {children}
            </m.div>
          </m.section>
        )}
      </AnimatePresence>
    </div>
  );
}
