export function fadeInOut(duration = 0.2, delay = 0) {
  return {
    from: {
      opacity: 0,
      transition: {
        type: "easeInOut",
        duration: duration,
      },
    },
    to: {
      opacity: 1,
      transition: {
        type: "easeInOut",
        duration: duration,
        delay: delay,
      },
    },
  };
}
