import React, { useReducer } from "react";
import { addItem, updateItem, removeItem } from "./ui.utils";
// import { getToken } from "@/framework/utils/get-token";
import { CartProvider } from "./cart/cart.context";
import { toast } from "react-toastify";
const initialState = {
  isAuthorized: false,
  displaySidebar: false,
  displayFilter: false,
  displayModal: false,
  displayShop: false,
  displayCart: false,
  displaySearch: false,
  modalView: "LOGIN_VIEW",
  drawerView: null,
  modalData: null,
  toastText: "",
  requestSizeProductName: "",
  sizeChart: "",
  totalFiltered: "",
  recommended: [],
  displayEmailPopup: false,
};

export const UIContext = React.createContext(initialState);

UIContext.displayName = "UIContext";

function uiReducer(state, action) {
  switch (action.type) {
    case "SET_AUTHORIZED": {
      return {
        ...state,
        isAuthorized: true,
      };
    }
    case "SET_UNAUTHORIZED": {
      return {
        ...state,
        isAuthorized: false,
      };
    }
    case "OPEN_SIDEBAR": {
      return {
        ...state,
        displaySidebar: true,
      };
    }
    case "CLOSE_SIDEBAR": {
      return {
        ...state,
        displaySidebar: false,
        drawerView: null,
      };
    }
    case "OPEN_CART": {
      return {
        ...state,
        displayCart: true,
      };
    }
    case "CLOSE_C": {
      return {
        ...state,
        displayCart: false,
      };
    }
    case "CLOSE_CART": {
      return {
        ...state,
        displayCart: false,
      };
    }
    case "CLOSE_EMAIL_POPUP": {
      return {
        ...state,
        displayEmailPopup: false,
      };
    }
    case "OPEN_SEARCH": {
      return {
        ...state,
        displaySearch: true,
      };
    }
    case "CLOSE_SEARCH": {
      return {
        ...state,
        displaySearch: false,
      };
    }
    case "OPEN_FILTER": {
      return {
        ...state,
        displayFilter: true,
      };
    }
    case "CLOSE_FILTER": {
      return {
        ...state,
        displayFilter: false,
      };
    }
    case "OPEN_SHOP": {
      return {
        ...state,
        displayShop: true,
      };
    }
    case "CLOSE_SHOP": {
      return {
        ...state,
        displayShop: false,
      };
    }
    case "OPEN_MODAL": {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      };
    }
    case "OPEN_EMAIL_POPUP": {
      return {
        ...state,
        displayEmailPopup: true,
      };
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        displayModal: false,
      };
    }
    case "SET_MODAL_VIEW": {
      return {
        ...state,
        modalView: action.view,
      };
    }
    case "SET_DRAWER_VIEW": {
      return {
        ...state,
        drawerView: action.view,
      };
    }
    case "SET_MODAL_DATA": {
      return {
        ...state,
        modalData: action.data,
      };
    }
    case "SET_TOAST_TEXT": {
      return {
        ...state,
        toastText: action.text,
      };
    }
    case "SET_REQUEST_SIZE_PRODUCT_NAME": {
      return {
        ...state,
        requestSizeProductName: action.text,
      };
    }
    case "SET_SIZE_CHART": {
      return {
        ...state,
        sizeChart: action.text,
      };
    }
    case "SET_USER_AVATAR": {
      return {
        ...state,
        userAvatar: action.value,
      };
    }
    case "SET_TOTAL_FILTERED_PRODUCTS": {
      return {
        ...state,
        totalFiltered: action.value,
      };
    }
    case "SET_RECOMMENDED_PRODUCTS": {
      const recommended = addItem(state.recommended, action.value);
      return {
        ...state,
        recommended,
      };
    }
    case "UPDATE_RECOMMENDED_PRODUCT": {
      const recommended = updateItem(
        state.recommended,
        action.slug,
        action.value
      );
      return {
        ...state,
        recommended,
      };
    }
    case "REMOVE_RECOMMENDED_PRODUCT": {
      const recommended = removeItem(state.recommended, action.slug);
      return {
        ...state,
        recommended,
      };
    }
    case "RESET_RECOMMENDED_PRODUCT": {
      const recommended = [];
      return {
        ...state,
        recommended,
      };
    }
  }
}

export const UIProvider = (props) => {
  const [state, dispatch] = useReducer(uiReducer, initialState);

  const value = React.useMemo(() => {
    const showToast = (message, type) => {
      toast[type](message);
    };
    const authorize = () => dispatch({ type: "SET_AUTHORIZED" });
    const unauthorize = () => dispatch({ type: "SET_UNAUTHORIZED" });
    const openSidebar = () => dispatch({ type: "OPEN_SIDEBAR" });
    const closeSidebar = () => dispatch({ type: "CLOSE_SIDEBAR" });
    const toggleSidebar = () =>
      state.displaySidebar
        ? dispatch({ type: "CLOSE_SIDEBAR" })
        : dispatch({ type: "OPEN_SIDEBAR" });
    const closeSidebarIfPresent = () =>
      state.displaySidebar && dispatch({ type: "CLOSE_CART" });
    const openCart = () => dispatch({ type: "OPEN_CART" });
    const closeCart = () => dispatch({ type: "CLOSE_CART" });
    const closeC = () => dispatch({ type: "CLOSE_C" });
    const toggleCart = () =>
      state.displaySidebar
        ? dispatch({ type: "CLOSE_CART" })
        : dispatch({ type: "OPEN_CART" });
    const closeCartIfPresent = () => {
      state.displaySidebar && dispatch({ type: "CLOSE_CART" });
    };

    const openFilter = () => dispatch({ type: "OPEN_FILTER" });
    const closeFilter = () => dispatch({ type: "CLOSE_FILTER" });
    const closeEmailPopup = () => dispatch({ type: "CLOSE_EMAIL_POPUP" });

    const openShop = () => dispatch({ type: "OPEN_SHOP" });
    const openEmailPopup = () => dispatch({ type: "OPEN_EMAIL_POPUP" });
    const closeShop = () => dispatch({ type: "CLOSE_SHOP" });

    const openModal = () => dispatch({ type: "OPEN_MODAL" });
    const closeModal = () => dispatch({ type: "CLOSE_MODAL" });
    const openSearch = () => dispatch({ type: "OPEN_SEARCH" });
    const closeSearch = () => dispatch({ type: "CLOSE_SEARCH" });

    const setUserAvatar = (_value) =>
      dispatch({ type: "SET_USER_AVATAR", value: _value });

    const setRequestSizeProductName = (text) =>
      dispatch({ type: "SET_REQUEST_SIZE_PRODUCT_NAME", text: text });

    const setSizeChart = (text) =>
      dispatch({ type: "SET_SIZE_CHART", text: text });

    const setModalView = (view) => dispatch({ type: "SET_MODAL_VIEW", view });
    const setDrawerView = (view) => dispatch({ type: "SET_DRAWER_VIEW", view });
    const setModalData = (data) => dispatch({ type: "SET_MODAL_DATA", data });
    const setTotalFiltered = (value) =>
      dispatch({ type: "SET_TOTAL_FILTERED_PRODUCTS", value: value });
    const setRecommendedProducts = (value) =>
      dispatch({ type: "SET_RECOMMENDED_PRODUCTS", value: value });
    const updateRecommendedProduct = (slug, value) =>
      dispatch({
        type: "UPDATE_RECOMMENDED_PRODUCT",
        slug: slug,
        value: value,
      });
    const removeRecommendedProduct = (slug) =>
      dispatch({ type: "REMOVE_RECOMMENDED_PRODUCT", slug: slug });
    const resetRecommendedProduct = () =>
      dispatch({ type: "RESET_RECOMMENDED_PRODUCT" });

    return {
      ...state,
      authorize,
      unauthorize,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openCart,
      closeCart,
      closeC,
      toggleCart,
      closeCartIfPresent,
      openFilter,
      closeFilter,
      openShop,
      closeShop,
      openModal,
      closeModal,
      openSearch,
      closeSearch,
      setModalView,
      setDrawerView,
      setUserAvatar,
      setModalData,
      setRequestSizeProductName,
      setSizeChart,
      setTotalFiltered,
      setRecommendedProducts,
      updateRecommendedProduct,
      removeRecommendedProduct,
      showToast,
      closeEmailPopup,
      openEmailPopup,
      resetRecommendedProduct,
    };
  }, [state]);

  return <UIContext.Provider value={value} {...props} />;
};

export const useUI = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};
// @ts-ignore
export const ManagedUIContext = ({ children }) => (
  // @ts-ignore
  <CartProvider>
    {/* @ts-ignore */}
    <UIProvider>{children}</UIProvider>
  </CartProvider>
);
