import React from "react";

export default function ChatIcon({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      <path d="M3.732 4.732A2.5 2.5 0 0 1 5.5 4h13A2.5 2.5 0 0 1 21 6.5V14a2.5 2.5 0 0 1-2.5 2.5h-7.293L6.5 21.207V16.5h-1A2.5 2.5 0 0 1 3 14V6.5a2.5 2.5 0 0 1 .732-1.768ZM5.5 5A1.5 1.5 0 0 0 4 6.5V14a1.5 1.5 0 0 0 1.5 1.5h2v3.293l3.293-3.293H18.5A1.5 1.5 0 0 0 20 14V6.5A1.5 1.5 0 0 0 18.5 5h-13ZM8 11V9.5h1V11H8Zm3.5 0V9.5h1V11h-1Zm3.5 0V9.5h1V11h-1Z"></path>
    </svg>
  );
}
