import { gql } from "./graphql";
export const PRODUCT_FIELDS_SINGLE = gql`
  fragment ProductFieldsSingle on ProductEntity {
    attributes {
      updatedAt
      name
      description
      onlyFewLeft
      expressDelivery
      slug
      outOfStock
      lowestPrice
      shipsIn18To20Days
      shipsIn24To25Days
      ratingValue
      reviewCount
      brands {
        data {
          attributes {
            name
          }
        }
      }
      variants(sort: "id:desc", pagination: { limit: 50 }) {
        id
        size
        salePrice
        compareAtPrice
        quantity
        phones(sort: "name:asc", pagination: { limit: 50 }) {
          data {
            attributes {
              name
              slug
            }
          }
        }
      }
      productType {
        data {
          attributes {
            name
          }
        }
      }
      images {
        data {
          attributes {
            url
            name
            alternativeText
            formats
          }
        }
      }
      productCollections {
        data {
          attributes {
            name
            slug
            parentCollection {
              data {
                attributes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
      SEO {
        metaTitle
        metaDescription
        metaImage {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
        metaSocial {
          description
        }
        keywords
        metaRobots
        structuredData
      }
    }
  }
`;
export const QUERY_ALL_PRODUCTS = gql`
  ${PRODUCT_FIELDS_SINGLE}
  query getAllProducts($limit: Int) {
    products(sort: "name:asc", pagination: { limit: $limit }) {
      data {
        ...ProductFieldsSingle
      }
    }
  }
`;
export const QUERY_ALL_PRODUCTS_SLUGS = gql`
  query getAllProducts($limit: Int) {
    products(sort: "name:asc", pagination: { limit: $limit }) {
      data {
        attributes {
          slug
        }
      }
    }
  }
`;
export const QUERY_PRODUCT_BY_SLUG = gql`
  ${PRODUCT_FIELDS_SINGLE}
  query getProductBySlug($slug: String!) {
    products(filters: { slug: { eq: $slug } }) {
      data {
        id
        ...ProductFieldsSingle
      }
    }
  }
`;
export const QUERY_PRODUCTS = gql`
  query products(
    $filter: ProductFiltersInput
    $limit: Int!
    $start: Int!
    $sortBy: [String]
  ) {
    products(
      filters: $filter
      pagination: { start: $start, limit: $limit }
      sort: $sortBy
    ) {
      data {
        attributes {
          name
          slug
          lowestPrice
          outOfStock
          variants(sort: "id:desc", pagination: { limit: 50 }) {
            id
            size
            salePrice
            compareAtPrice
            quantity
          }
          images {
            data {
              attributes {
                url
                name
                alternativeText
                formats
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
