import React, { useEffect, useState } from "react";
import Button from "../ui/button";
import { useForm } from "react-hook-form";
import FloatingInput from "../ui/floating-input";
import FileUpload from "../ui/fileUpload";

export default function ZohoTicketForm({ selectedSubmenu }) {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    if (selectedSubmenu == "No, my request is not specified above.") {
      setValue("topic", "");
    } else {
      setValue("topic", selectedSubmenu);
    }
  }, [selectedSubmenu]);

  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {
    setProcessing(true);
    const formData = new FormData();

    try {
      // Send the ticket data to the Next.js API route
      const response = await fetch("/api/create-ticket", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject: data?.orderNumber
            ? data?.orderNumber + "-" + data?.topic
            : data?.topic,
          description: data?.message,
          departmentId: "168671000000010772",
          email: data?.email,
          phone: data?.mobile,
          contact: {
            email: data?.email,
            firstName: data?.firstName,
            lastName: data?.lastName,
            phone: data?.mobile,
            // state: data?.state,
            // city: data?.city,
            // country: "India",
            // zip: data?.pincode,
          },
        }),
      });

      const result = await response.json();
      if (response.ok) {
        if (result?.data?.id && data.attachment[0]) {
          formData.append("file", data.attachment[0]);
          formData.append("ticketId", result?.data?.id);
          try {
            const response = await fetch("/api/upload-attachment", {
              method: "POST",
              body: formData,
            });
            if (response.ok) {
              console.log("file uploaded successfully.");
            } else {
              console.log(response);
              setError(
                "Failed to upload attachemnt. Please try again or contact admin support."
              );
            }
          } catch (error) {
            console.log(error);
            setError(
              "Failed to upload attachemnt. Please try again or contact admin support."
            );
          }
        }

        setSuccess(true);
      } else {
        setError(
          "Failed to create ticket. Please try again or contact admin support."
        );
      }
    } catch (error) {
      setError(
        "Failed to create ticket. Please try again or contact admin support."
      );
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="bg-[#F9F7F6] w-full md:px-4 px-0 md:py-8 py-4 rounded-md overflow-hidden">
      {success && (
        <p className="w-full border-dashed border-[1px] rounded-2xl border-black text-sm md:text-base text-center px-4 py-8">
          Your support ticket has been successfully created!
          <br /> Our team will review it and get back to you shortly. <br />
          Thank you for reaching out!
        </p>
      )}
      {(success === null || !success) && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-6 px-4 bg-[#F9F7F6] rounded-md overflow-hidden"
          noValidate
          autoComplete="off"
        >
          <h4 className="md:text-xl text-base text-black font-bold mb-0">
            Please enter the following details:
          </h4>

          <FloatingInput
            labelKey="Topic*"
            name="topic"
            type="text"
            {...register("topic", {
              required: "Please provide a topic.",
            })}
            errorKey={errors.topic?.message}
            watch={watch}
          />
          <FloatingInput
            labelKey="Order Number"
            name="orderNumber"
            type="text"
            {...register("orderNumber")}
            errorKey={errors.orderNumber?.message}
            watch={watch}
          />

          <FloatingInput
            labelKey="Email*"
            name="email"
            type="email"
            variant="solid"
            {...register("email", {
              required: `${"Please provide your email address"}`,
              pattern: {
                value:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please provide a valid email address",
              },
            })}
            errorKey={errors.email?.message}
            watch={watch}
          />
          <FloatingInput
            labelKey="Mobile Number*"
            name="mobile"
            type="tel"
            {...register("mobile", {
              required: `${"Please provide your mobile number"}`,
              pattern: {
                value: /^[6789]\d{9}$/,
                message: "Please provide a valid mobile number",
              },
            })}
            errorKey={errors.mobile?.message}
            watch={watch}
          />

          <div className="grid md:grid-cols-2 grid-cols-1 auto-rows-auto md:gap-10 gap-8 w-full">
            <FloatingInput
              labelKey="First Name*"
              name="firstName"
              type="text"
              {...register("firstName", {
                required: "Please provide your first name.",
              })}
              errorKey={errors.firstName?.message}
              watch={watch}
            />
            <FloatingInput
              labelKey="Last Name*"
              name="lastName"
              type="text"
              {...register("lastName", {
                required: "Please provide your last name.",
              })}
              errorKey={errors.lastName?.message}
              watch={watch}
            />
          </div>
          <FloatingInput
            labelKey="Message*"
            name="message"
            type="textarea"
            {...register("message", {
              required: "Please provide your message.",
            })}
            errorKey={errors.message?.message}
            watch={watch}
          />
          <FileUpload
            placeholderKey="Upload Attachment"
            type="file"
            name="attachment"
            errorKey={errors.attachment?.message}
            ref={register("attachment")}
            setValue={setValue}
            clearErrors={clearErrors}
          />
          {error && <p className="my-2 text-sm text-red">{error}</p>}
          <Button
            type="submit"
            className="w-auto mt-4"
            loading={processing}
            disabled={processing}
          >
            {processing ? "Submitting..." : "Submit"}
          </Button>
        </form>
      )}
    </div>
  );
}
