import { useUI } from "@/contexts/ui.context";
import useScrollLock from "@/utils/use-scroll-lock";
import Modal from "./modal";
import dynamic from "next/dynamic";
const LoginForm = dynamic(() => import("@/components/auth/login-form"), {});
const SignUpForm = dynamic(() => import("@/components/auth/sign-up-form"), {});
const RequestSize = dynamic(() => import("@/containers/request-size"), {});
const SizeChart = dynamic(() => import("@/containers/size-chart"), {});
const ForgetPasswordForm = dynamic(
  () => import("@/components/auth/forget-password-form"),
  {}
);

export default function ManagedModal() {
  const { displayModal, closeModal, modalView } = useUI();
  useScrollLock(displayModal);
  return (
    <Modal open={displayModal} onClose={closeModal}>
      {modalView === "LOGIN_VIEW" && <LoginForm />}
      {modalView === "SIGN_UP_VIEW" && <SignUpForm />}
      {modalView === "FORGET_PASSWORD" && <ForgetPasswordForm />}
      {/* {modalView === "NEWSLETTER_VIEW" && <Newsletter />} */}
      {modalView === "REQUEST_SIZE_VIEW" && <RequestSize />}
      {modalView === "SIZE_CHART_VIEW" && <SizeChart />}
    </Modal>
  );
}
