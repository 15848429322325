import cn from "classnames";
import React from "react";

const classes = {
  root: "rounded-md py-2 px-4 md:px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-sm lg:text-sm font-body placeholder-body min-h-10 transition duration-200 ease-in-out",
  normal:
    "bg-gray-100 border-gray-300 focus:shadow focus:bg-white focus:border-primary",
  solid:
    "bg-white border-gray-300 focus:outline-none focus:border-heading h-10",
  outline: "border-gray-300 focus:border-primary",
  shadow: "focus:shadow",
};
const Input = React.forwardRef(
  (
    {
      className = "block",
      labelKey,
      name,
      errorKey,
      placeholderKey,
      variant = "normal",
      shadow = false,
      type = "text",
      inputClassName,
      ...rest
    },
    ref
  ) => {
    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === "normal",
        [classes.solid]: variant === "solid",
        [classes.outline]: variant === "outline",
      },
      {
        [classes.shadow]: shadow,
      },
      inputClassName
    );
    return (
      <div className={className}>
        {labelKey && (
          <label
            htmlFor={name}
            className="block text-body font-semibold text-sm leading-none mb-2 cursor-pointer"
          >
            {labelKey}
          </label>
        )}
        <input
          id={name}
          name={name}
          type={type}
          ref={ref}
          // @ts-ignore
          placeholder={placeholderKey}
          className={rootClassName}
          autoComplete="off"
          spellCheck="false"
          aria-invalid={errorKey ? "true" : "false"}
          {...rest}
        />
        {errorKey && <p className="my-2 text-sm text-red">{errorKey}</p>}
      </div>
    );
  }
);
Input.displayName = "Input";
export default Input;
