export const supportData = [
  {
    option: "Order and Delivery Status",
    subMenu: [
      {
        option: "Delivery Status",
        subMenu: [
          {
            question: "When will my order arrive?",
            answer: (
              <>
                <p>
                  Once your order is shipped, you will receive a shipping
                  confirmation email containing tracking details and carrier
                  information. From there, you can monitor the progress of your
                  shipment.
                </p>
                <p>
                  Typically, after dispatch, orders arrive within 2-3 working
                  days. However, if your address is in a remote area with
                  limited courier services, delivery may take up to 6-7 working
                  days.
                </p>
                <p>
                  If your order includes a timeline of 18-20 or 24-25 working
                  days (as noted on the product page), rest assured your product
                  is progressing as planned and will be shipped by the end of
                  the specified period.
                </p>
              </>
            ),
          },
          {
            question: "How can I track my order?",
            answer: (
              <>
                <p>
                  When your order ships, you will receive an email with a “Track
                  Order” button in blue colour, which redirects to your tracking
                  information. Please note that tracking links can take
                  approximately 3-4 hours to activate.
                </p>
                <p>
                  If your tracking link is inactive or if you encounter any
                  issues, please reach out to us using the contact options
                  below, and we’ll be happy to assist. Alternatively, you can
                  also reach out to the customer care of the courier company.
                </p>
              </>
            ),
          },
          {
            question: "I’m unable to track my order.",
            answer: (
              <>
                <p>
                  If you haven’t yet received a shipping confirmation email,
                  this means your order is still being processed and hasn’t
                  shipped yet. As soon as it leaves our facility, you’ll get an
                  email with tracking details.
                </p>
                <p>
                  If you’ve received a confirmation but can’t see updates on the
                  tracking page, give it some time to refresh. If the page
                  remains inactive or blank for longer than 24 hours after
                  receiving the mail, please let us know, and we’ll look into it
                  for you.
                </p>
              </>
            ),
          },
          {
            question: "Why is my order delayed?",
            answer: (
              <>
                <p>
                  We’re sorry your order is taking longer than expected.
                  Sometimes, factors like seasonal demand, holidays, or courier
                  issues can slow down processing times. Please be rest assured
                  that we’re actively working on your order, and our goal is to
                  get it to you as quickly as possible.
                </p>
                <p>
                  Thank you for your patience – we’re confident the wait will be
                  worth it once your item arrives!
                </p>
              </>
            ),
          },
          {
            question:
              "My order has been shipped but hasn’t been delivered—what should I do?",
            answer: (
              <>
                <p>
                  If your order status shows it has shipped, refer to the
                  tracking information by clicking the Track Order button in the
                  shipping mail to follow its journey. Occasionally, courier
                  delays can occur, and we apologize for any inconvenience in
                  advance.
                </p>
                <p>
                  If tracking hasn’t updated for an extended period, please
                  reach out using the contact options below. We’ll contact the
                  courier on your behalf to expedite delivery.
                </p>
                <p>
                  On average, orders arrive within 3-4 working days after
                  shipping, or up to 6-7 working days if it’s headed to a remote
                  area.
                </p>
              </>
            ),
          },
          {
            question:
              "My order is being returned to the sender—what happens next?",
            answer: (
              <>
                <p>
                  If your order is being returned, this may be due to an issue
                  with delivery proximity, an incorrect address, or difficulties
                  reaching you by phone. Once the package returns to us, we’ll
                  ensure it’s re-shipped to you safely.
                </p>
                <p>
                  If you’ve received a notification that your order is being
                  returned, please contact us below. We’ll do our best to either
                  attempt redelivery or arrange a quick re-shipment to ensure it
                  reaches you soon.
                </p>
              </>
            ),
          },
          {
            question: "My tracking screen is blank, how can I fix this? ",
            answer: (
              <>
                <p>
                  If your tracking screen is blank, this is likely because your
                  order was initially processed with one courier, but due to
                  availability or coverage limitations , we’ve opted to use a
                  different single courier for the entire delivery. When
                  switching to this courier, tracking updates may take a moment
                  to refresh as the new details are generated.
                </p>
                <p>
                  Please check your email (including spams and promotions
                  folder) for any updated tracking information from us. If you
                  haven’t received an update, or if the tracking remains blank,
                  feel free to contact us using the options below, and we’ll
                  provide the latest details on your order.
                </p>
              </>
            ),
          },
          {
            question:
              "My order tracking status has been showing out for delivery for a couple of days, how can I fix this?",
            answer: (
              <>
                <p>
                  If your tracking status has been stuck on “Out for Delivery”
                  for more than a day, it’s possible there was an issue with the
                  courier’s delivery attempt. Sometimes, due to high demand,
                  unexpected delays, or delivery area constraints, updates can
                  be delayed.
                </p>
                <p>
                  To resolve this, please reach out to us using the contact
                  options below. We’ll contact the courier on your behalf to
                  check on the status and push for an expedited delivery. Thank
                  you for your patience, and rest assured we’ll do everything we
                  can to get your order to you as soon as possible.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Lost Order",
        subMenu: [
          {
            question:
              "My order was delivered to the wrong address—what should I do?",
            answer: (
              <>
                <p>
                  Oh no! It’s possible we may have accidentally sent you the
                  wrong tracking details. First, please check if the tracking
                  information has the same name as on the order. If it doesn’t,
                  no need to worry—your parcel is likely still on its way to
                  you.
                </p>
                <p>
                  Either way, please reach out to us, and we’ll jump right on it
                  to make sure your order is headed to the correct address or to
                  provide you with the right tracking information. We’re here to
                  help get this sorted out for you as quickly as possible!
                </p>
              </>
            ),
          },
          {
            question:
              "My order is marked as delivered, but I haven’t received it—how do I resolve this?",
            answer: (
              <>
                <p>
                  We’re here to help figure this out! First, check that the
                  tracking shows your name as the recipient. Our couriers
                  require a signature upon delivery, so your order shouldn’t be
                  marked as delivered without this step.
                </p>
                <p>
                  If you’re still without your order, reach out to us—we’ll
                  check in with the courier right away and can also provide you
                  with a copy of the signature used to confirm delivery. Let us
                  know, and we’ll do everything we can to ensure your order gets
                  to you soon!
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Missing / Incorrect Items",
        subMenu: [
          {
            question: "What should I do if I receive the wrong item or size?",
            answer: (
              <>
                <p>
                  Don’t worry! Your correct order is safe with us, and this
                  mix-up is likely just a minor shipping error. Please reach out
                  to us right away with your order number and a brief
                  description of the issue, and we’ll get it resolved for you
                  promptly. We&apos;re here to help make sure you get the right
                  item in no time!
                </p>
              </>
            ),
          },
          {
            question: "What steps should I take if my order arrives damaged?",
            answer: (
              <>
                <p>
                  We’re sorry to hear that your item arrived in
                  less-than-perfect condition! Please contact us and share a few
                  images of the item so our team can quality-check the issue.
                  Once we confirm the damage, we’ll arrange a return pickup for
                  you to send it back. As soon as it reaches us, our team will
                  inspect it again, and if confirmed, we’ll offer you a choice
                  of a replacement, refund, or store credit. Just reach out to
                  us, and we’ll handle it from there!
                </p>
              </>
            ),
          },
          {
            question: "How can I initiate a return for my order?",
            answer: (
              <>
                <p>
                  To see if your item qualifies for a return, please review our{" "}
                  <a
                    href="https://hypefly.co.in/policies/return-policy"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    Return Policy
                  </a>
                  . If your item meets the return criteria, such as being
                  damaged or defective, you can reach out to us to open a return
                  request, and we’ll guide you through the process. Please note
                  that we do not accept returns for size exchanges or change of
                  mind, as per our policy.
                </p>
              </>
            ),
          },
          {
            question:
              "I had ordered multiple items, but have only received part of it, where are the other items?",
            answer: (
              <>
                <p>
                  If you’ve received only part of your order, no need to worry!
                  We ship items individually, so it’s possible that the rest of
                  your items are either awaiting dispatch or already on their
                  way. You’ll receive a separate tracking email for each
                  shipment. If you have any questions about the remaining items,
                  just let us know—we’re happy to help!
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "Return and Refund Related",
    subMenu: [
      {
        option: "Return Policy and Eligibility for a Return",
        subMenu: [
          {
            question: "What is your return policy?",
            answer: (
              <>
                <p>
                  You can view our full return policy{" "}
                  <a
                    href="https://hypefly.co.in/policies/return-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  . If you have any specific questions, don’t hesitate to
                  contact us—we’re here to help clarify anything you need!
                </p>
              </>
            ),
          },
          {
            question:
              "Can I initiate a return due to a change of mind or size issues?",
            answer: (
              <>
                <p>
                  Currently, we do not offer returns for changes of mind or
                  sizing issues. Please refer to our{" "}
                  <a
                    href="https://hypefly.co.in/policies/return-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Return Policy
                  </a>{" "}
                  for more details. If you need additional help or have further
                  questions, feel free to submit a ticket below.
                </p>
              </>
            ),
          },
          {
            question: "What is  the criteria for an eligible return?",
            answer: (
              <>
                <p>
                  Our return policy generally covers returns for items with
                  defects, quality control issues, or authenticity concerns. For
                  a detailed breakdown of eligibility, please refer to our
                  <a
                    href="https://hypefly.co.in/policies/return-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Return Policy
                  </a>
                  . If you’re experiencing any of these issues, do reach out to
                  us, and we’ll guide you through the process.
                </p>
              </>
            ),
          },
          {
            question: "Am I responsible for paying return shipping costs?",
            answer: (
              <>
                <p>
                  No, if the return is due to an error on our end, we’ll cover
                  all shipping costs from start to finish.
                </p>
              </>
            ),
          },
          {
            question:
              "What is the timeframe for initiating a return for eligible products?",
            answer: (
              <>
                <p>
                  You can initiate a return within 7 working days from the date
                  of delivery. If you’re facing an issue, please get in touch
                  with us promptly, and we’ll take it from there.
                </p>
              </>
            ),
          },
          {
            question: "How long does it take to process a return?",
            answer: (
              <>
                <p>
                  Once you initiate a return with us, please share a few images
                  of the item for quality-check purposes. After our team reviews
                  and confirms the issue, we’ll arrange a return pickup. When
                  the item arrives back with us, we’ll inspect it again, and if
                  eligible, offer you a choice of a replacement, refund, or
                  store credit. Just reach out, and we’ll handle it smoothly
                  from there!
                </p>
              </>
            ),
          },
          {
            question:
              "My return is delayed or hasn’t been picked up—what should I do?",
            answer: (
              <>
                <p>
                  If your return pickup is delayed or hasn’t occurred yet,
                  please reach out to us, and we’ll work with the courier to get
                  it sorted as soon as possible. We’re here to make the process
                  easy and convenient for you.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Refund Policy",
        subMenu: [
          {
            question: "Under what conditions do I get a refund?",
            answer: (
              <>
                <p>
                  We offer refunds for eligible returns, such as items with
                  defects, quality control issues, or authenticity concerns. If
                  you’ve received an item that falls under these categories,
                  please reach out to us, and we’ll guide you through the return
                  process. Our goal is to make sure you’re satisfied with your
                  purchase, so don’t hesitate to contact us if you think your
                  order qualifies for a refund.
                </p>
              </>
            ),
          },
          {
            question:
              "How long does it take to receive my refund after returning an item?",
            answer: (
              <>
                <p>
                  Once your returned item arrives back at our warehouse, it goes
                  through a quick inspection, which usually takes 2-3 working
                  days. After that, we’ll process your refund, which will be
                  sent back to the original payment method you used when placing
                  your order. Typically, refunds are credited within 3-5 working
                  days depending upon the source mode of payment If you paid via
                  EMI, your initial EMI will be refunded, and any future EMIs
                  will be automatically canceled.
                </p>
              </>
            ),
          },
          {
            question:
              "Will I receive a full refund, or are there any deductions?",
            answer: (
              <>
                <p>
                  No need to worry—we issue a full refund on eligible returns,
                  with no deductions. We want you to feel assured that when an
                  issue arises, we’ll make it right.{" "}
                </p>
              </>
            ),
          },
          {
            question:
              "How will I be refunded if I used HypeFly Pay Later (EMI) for my order?",
            answer: (
              <>
                <p>
                  If you paid through HypeFly Pay Later (EMI), your initial EMI
                  payment will be refunded to you, and any future EMIs will be
                  automatically canceled. This way, your refund is handled
                  smoothly, without further charges.
                </p>
              </>
            ),
          },
          {
            question:
              "What are the refund options available if I paid by credit card?",
            answer: (
              <>
                <p>
                  If you paid by credit card, the refund will be credited back
                  to the same credit card you used when placing the order. Our
                  aim is to ensure the process is simple and transparent.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Track the Status of my Return/Refund",
        subMenu: [
          {
            question: "How can I track the status of my refund?",
            answer: (
              <>
                <p>
                  While refunds usually take about 3-5 days to process, you’re
                  always welcome to reach out to us for a quick update. We’ll be
                  happy to provide you with the latest information on your
                  refund status!
                </p>
              </>
            ),
          },
          {
            question: "Why is my refund delayed or still processing?",
            answer: (
              <>
                <p>
                  If your refund is still in progress, it may be because our
                  team is inspecting your returned item and working through the
                  quality control steps. This process usually takes about 3-5
                  days once the item reaches our warehouse. If additional
                  information is needed, our team may have reached out to you by
                  email, so please check your inbox. For any questions or a
                  status update, feel free to contact us, and we’ll help
                  expedite the process if possible
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "EMI and Payment Methods",
    subMenu: [
      {
        option: "Regarding Hypefly PayLater EMI’s",
        subMenu: [
          {
            question: "What is HypeFly Pay Later, and how does it work?",
            answer: (
              <>
                <p>
                  HypeFly Pay Later is our convenient EMI system, allowing you
                  to break down payments into 3, 6, or 9-month installments with
                  minimal or no interest. Payments are made via UPI, making it
                  easy and accessible. When you choose HypeFly Pay Later at
                  checkout, you’ll go through a quick credit check via our
                  gateway. If approved, your first EMI will be charged, and the
                  autopay setup will kick in automatically.
                </p>
                <p>
                  Once the first EMI is processed, your order is confirmed and
                  ready to ship right away—no waiting, just easy installments!
                </p>
              </>
            ),
          },
          {
            question: "What happens if an autopay or EMI repayment fails?",
            answer: (
              <>
                <p>
                  If an autopay or EMI payment doesn’t go through, please reach
                  out to us, and we’ll guide you through the steps to resolve it
                  and ensure no interruption to your order.
                </p>
              </>
            ),
          },
          {
            question: "How can I check my eligibility for EMI on my order?",
            answer: (
              <>
                <p>
                  Eligibility for HypeFly Pay Later is checked instantly at
                  checkout. When you select the EMI option, the system will
                  perform a quick credit check on the spot. If you’re eligible,
                  it will take you to the next step. If not, you’ll be notified
                  right there, making it hassle-free.
                </p>
              </>
            ),
          },
          {
            question:
              "What if I am not eligible for HypeFly PayLater? Will choosing the EMI option affect my order’s delivery timeline?",
            answer: (
              <>
                <p>
                  If you’re not eligible, it might be due to outstanding EMIs or
                  a low credit score. But rest assured, selecting the EMI option
                  will not impact your delivery timeline. As soon as your
                  initial payment is processed, your order is confirmed and
                  moves through our standard processing and shipping timeline.
                </p>
              </>
            ),
          },
          {
            question:
              "How is interest calculated on EMI purchases, and are there additional charges?",
            answer: (
              <>
                <p>
                  Interest rates are based on your credit score and purchase
                  history. You’ll be notified of any interest charges before you
                  make your first EMI payment, so there are no surprises. The
                  only additional cost is the interest itself, which is clearly
                  displayed before placing your order.
                </p>
              </>
            ),
          },
          {
            question: "Can I make a prepayment or pay off my EMI early?",
            answer: (
              <>
                <p>
                  Yes, you absolutely can! To settle your EMI balance early, you
                  can make a manual payment without needing to go through the
                  monthly EMI process.
                </p>
              </>
            ),
          },
          {
            question:
              "What happens if my order gets cancelled after selecting the EMI option?",
            answer: (
              <>
                <p>
                  If your order is canceled, you’ll receive a full refund for
                  all EMIs paid to date within 2-3 working days, including any
                  interest. Additionally, any future scheduled EMIs will be
                  automatically canceled, giving you complete peace of mind.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Information regarding modes of Payment",
        subMenu: [
          {
            question: "Do you accept Cash on Delivery (COD)?",
            answer: (
              <>
                <p>
                  Currently, we do not offer Cash on Delivery (COD) as a payment
                  option. However, we do provide a variety of secure payment
                  methods, including UPI, debit card, credit card, net banking,
                  and EMI options to make the checkout experience convenient for
                  you.
                </p>
              </>
            ),
          },
          {
            question: "Am I responsible for paying custom duties on my order?",
            answer: (
              <>
                <p>
                  Nope, you’re all set! All our orders are shipped domestically
                  from our warehouses in Mumbai and Delhi. Once you place your
                  order, the amount at checkout is final—there are no extra
                  customs duties or taxes to worry about.
                </p>
              </>
            ),
          },
          {
            question: "Will my card information be stored in your database?",
            answer: (
              <>
                <p>
                  No, we do not store any card information directly in our
                  database. Your payment is processed through a secure,
                  RBI-approved payment gateway, trusted by thousands of verified
                  stores. Our gateway uses industry-standard encryption and
                  security protocols, ensuring that your card details are kept
                  safe and private.
                </p>
              </>
            ),
          },
          {
            question: "What EMI options are available for payment?",
            answer: (
              <>
                <p>
                  We offer a range of EMI options, including UPI-based EMI, as
                  well as credit card and cardless EMIs. This flexibility allows
                  you to choose the option that best fits your needs when
                  placing an order.
                </p>
              </>
            ),
          },
          {
            question: "What payment methods do you accept?",
            answer: (
              <>
                <p>
                  We accept a range of payment methods to make your checkout
                  experience smooth and convenient. These include UPI, debit
                  card, credit card, EMI options (via UPI and credit cards), net
                  banking, and cardless EMI. With these multiple options, you
                  can choose the one that works best for you at checkout.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "Payment / Order Failure",
    subMenu: [
      {
        option:
          "Payment has been deducted but the order failed/has not been confirmed",
        subMenu: [
          {
            question:
              "What should I do if my payment was deducted but I didn’t receive an order confirmation?",
            answer: (
              <>
                <p>
                  We understand this may feel worrying, but rest assured,
                  there’s a process in place to resolve it. If your payment was
                  deducted, your funds are safe with us. There are two possible
                  reasons for this: (1) The payment timed out, and the order
                  couldn’t be confirmed, or (2) The payment was processed but
                  hasn’t reached us yet and will likely be refunded to you.
                </p>
                <p>
                  In either case, please open a support ticket and provide us
                  with the following details: your name, phone number, email ID,
                  the amount paid, the product you intended to purchase, and the
                  date of the transaction. Our team will promptly review it and
                  either manually confirm your order and send you a confirmation
                  email or process a refund if needed.
                </p>
              </>
            ),
          },
          {
            question:
              "How long does it take to receive a refund for a failed payment?",
            answer: (
              <>
                <p>
                  If the order didn’t go through, refunds for failed payments
                  are typically processed within 3-5 working days. If the order
                  is confirmed, you’ll receive an order confirmation instead of
                  a refund. We’ll keep you updated and notify you once the
                  refund or order confirmation is in process.
                </p>
              </>
            ),
          },
          {
            question:
              "What steps should I take if my payment failed but the amount was deducted from my account?",
            answer: (
              <>
                <p>
                  Please open a support ticket and reach out to us with the
                  following information: your name, phone number, email ID, the
                  amount paid, the product you intended to purchase, and the
                  date of the transaction. Our team will review the details and
                  will either confirm your order manually and send a
                  confirmation email or initiate a refund for the deducted
                  amount.
                </p>
              </>
            ),
          },
          {
            question: "How can I verify that my order was placed successfully?",
            answer: (
              <>
                <p>
                  Once your order is successfully placed, you will receive a
                  confirmation email. If you’ve received this email, you can be
                  assured that your order is confirmed and will be processed
                  promptly. If you haven’t received a confirmation email, please
                  contact us right away, and we’ll help verify the order status
                  for you.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Unable to proceed to payment",
        subMenu: [
          {
            question: "Why was my payment declined, and how can I resolve it?",
            answer: (
              <>
                <p>
                  If you’re using HypeFly Pay Later (EMI), a payment decline
                  could mean that you didn’t meet the eligibility requirements
                  at the time, possibly due to existing EMI commitments or a
                  lower credit score. Please reach out to us, and we’ll help
                  clarify the reason for the decline.
                </p>
                <p>
                  For other payment methods like UPI, debit cards, credit cards,
                  or net banking, a decline could occur due to issues with the
                  payment gateway, bank verification, or eligibility for the
                  card you’re using. If this happens, don’t worry—we’re here to
                  assist. Reach out to us, and we’ll guide you through the steps
                  to ensure a successful transaction. If necessary, we can also
                  help arrange an alternative payment method to confirm your
                  order.
                </p>
              </>
            ),
          },
          {
            question:
              "Can I reattempt payment if the initial transaction failed?",
            answer: (
              <>
                <p>
                  Absolutely! You’re welcome to try the payment again, and if
                  it’s giving you any trouble, please don’t hesitate to contact
                  us. We’ll assign a dedicated team member to guide you through
                  the process, troubleshoot any issues, and make sure your
                  payment goes through smoothly so that your order is confirmed
                  without further delay. We’re here to help make things easy for
                  you.
                </p>
              </>
            ),
          },
          {
            question:
              "What should I do if my preferred payment method is not supported?",
            answer: (
              <>
                <p>
                  If you don’t see your preferred payment method, please let us
                  know right away. We’ll connect you with one of our team
                  members, who will work with you to arrange a safe and
                  convenient alternative. Our goal is to ensure that your order
                  can be completed seamlessly, and we’re happy to help find the
                  best solution for you.
                </p>
              </>
            ),
          },
          {
            question:
              "Who can I contact if I haven’t received my refund after a payment failure?",
            answer: (
              <>
                <p>
                  If it’s been more than the usual 3-5 working days and you
                  haven’t seen the refund in your account, please open a support
                  ticket. We’ll promptly review your case and get back to you
                  with an update on your refund status. Rest assured, we’ll keep
                  you informed and ensure that your refund reaches you as
                  quickly as possible.
                </p>
              </>
            ),
          },
          {
            question:
              "Will I receive a confirmation email or SMS once my order is placed?",
            answer: (
              <>
                <p>
                  Yes, once your order is confirmed, you’ll receive a
                  confirmation email with all your order details. This means
                  your order is securely in our system and is moving forward in
                  the process. If you don’t receive the email within a few
                  minutes, please check your spam folder. If it’s still not
                  there, feel free to reach out to us, and we’ll be happy to
                  confirm your order status.
                </p>
              </>
            ),
          },
          {
            question: "I cannot find my order ID.",
            answer: (
              <>
                <p>
                  If you’ve misplaced your order ID, no problem at all! Just
                  open a support ticket and share the following details: your
                  name, phone number, email, the product you ordered, and the
                  purchase date. Our team will locate your order in our system
                  and send you the order ID promptly. We’re here to help you
                  with any questions, big or small.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "Cancellation and Modification of Order",
    subMenu: [
      {
        option: "Order Cancellation Related",
        subMenu: [
          {
            question:
              "Under what circumstances might my order be canceled by the company?",
            answer: (
              <>
                <p>
                  In rare cases, your order may be canceled if the item is out
                  of stock. We always strive to fulfill every order and, if
                  possible, will attempt to source the pair specifically for
                  you. As a last resort, we may offer an alternative pair for
                  you to consider. If none of these options work out, we’ll
                  proceed with canceling and fully refunding your order. Our
                  goal is always to keep you informed and offer the best
                  possible solution.
                </p>
              </>
            ),
          },
          {
            question:
              "How can I cancel my order, and what is your order cancellation policy?",
            answer: (
              <>
                <p>
                  All sales are final and cannot be canceled once confirmed.
                  However, if you reach out to us before the order is processed,
                  we may be able to edit the order or provide store credit for
                  future use* (subject to discretion). Once the order is
                  processed (you’ll receive an &quot;Order Processed&quot;
                  email), no further changes can be made. If you’re unsure,
                  contact us as soon as possible, and we’ll do our best to
                  assist before processing begins.
                </p>
              </>
            ),
          },
          {
            question:
              "Will I be notified if my order is canceled by the company?",
            answer: (
              <>
                <p>
                  Yes, absolutely. If for any reason we need to cancel your
                  order, we’ll notify you promptly via email with all the
                  details.
                </p>
              </>
            ),
          },
          {
            question:
              "How long does it take to receive a refund if my order is canceled?",
            answer: (
              <>
                <p>
                  Once we notify you of the cancellation, the refund is
                  processed immediately. It typically takes 3-5 working days for
                  the refund to reflect in your original payment method. We’ll
                  keep you updated to ensure the process is smooth and timely.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Order Modification Related",
        subMenu: [
          {
            question:
              "Can I modify or change items in my order after they have been placed?",
            answer: (
              <>
                <p>
                  Yes, modifications to your order, such as changing sizes or
                  swapping items, can be made until the order is processed*.
                  Once the order is processed, however, no further edits can be
                  made to items, sizes, or any other order details. You’ll
                  receive an &quot;Order Processed&quot; email as soon as
                  processing is complete, which means changes are no longer
                  possible. To make adjustments before that, simply open a
                  support ticket, and we’ll help make the changes for you
                </p>
              </>
            ),
          },
          {
            question:
              "What is the deadline for making changes to my order details?",
            answer: (
              <>
                <p>
                  You can modify your order details up until it is processed,
                  which usually happens within 3 hours after placing the order.
                  During this window, you’re welcome to update items, sizes, or
                  contact details by reaching out to us. Once processing is
                  complete (indicated by an &quot;Order Processed&quot; email),
                  further edits cannot be made. So, if you need to make changes,
                  it’s best to contact us as soon as possible after placing your
                  order.
                </p>
              </>
            ),
          },
          {
            question: "How can I update my contact information for my order?",
            answer: (
              <>
                <p>
                  You can update your contact or delivery information any time
                  before the order is shipped. As long as your order hasn’t been
                  dispatched and you haven’t received tracking details, you can
                  reach out to us to modify personal or shipping information.
                  Simply open a support ticket below, and we’ll take care of it
                  for you.
                </p>
              </>
            ),
          },
          {
            question:
              "Is it possible to change the payment method for an existing order?",
            answer: (
              <>
                <p>
                  Once a payment is completed, the payment method cannot be
                  changed for that order. If you’re having specific payment
                  issues, please contact us for assistance, and we’ll do our
                  best to help.
                </p>
              </>
            ),
          },
          {
            question: "Can I adjust the size of an item in my order?",
            answer: (
              <>
                <p>
                  Yes, you can adjust the size of an item in your order up until
                  it is processed. To do so, please open a support ticket with
                  the new size details. We’ll make sure the change is noted
                  before processing. Once the order is processed (you’ll receive
                  an &quot;Order Processed&quot; email), size changes or other
                  modifications will no longer be possible.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Address and Personal Details Modification",
        subMenu: [
          {
            question: "How can I modify my order details after placing it?",
            answer: (
              <>
                <p>
                  You can update personal details, such as your contact number,
                  email address, and delivery information, any time before your
                  order is shipped. As long as your order hasn’t been
                  dispatched, simply open a support ticket, and we’ll help you
                  make the necessary updates. Once tracking details are sent to
                  you, though, modifications won’t be possible. If you need to
                  adjust something, reach out to us as soon as you can, and
                  we’ll make it a priority to assist.
                </p>
              </>
            ),
          },
          {
            question:
              "Is it possible to change my shipping address or other contact information after confirming my purchase?",
            answer: (
              <>
                <p>
                  Yes, you can update your shipping address and contact
                  information until your order has been shipped. If you’d like
                  to make any changes, just open a support ticket with the new
                  details, and we’ll ensure that your order is updated
                  accordingly. Once your order is shipped and tracking
                  information is provided, further changes won’t be possible, so
                  we recommend reaching out as soon as you can if updates are
                  needed.
                </p>
              </>
            ),
          },
          {
            question:
              "Will updating my personal or shipping details affect the delivery timeline?",
            answer: (
              <>
                <p>
                  No, updating personal or shipping details won’t affect the
                  delivery timeline. As long as the order is not yet shipped,
                  our team can adjust the details without any delay. However,
                  the shipping time from dispatch will differ from region to
                  region. Just open a support ticket to let us know about the
                  changes, and we’ll take care of it promptly.
                </p>
              </>
            ),
          },
          {
            question:
              "Can I add or remove items from my order once it has been confirmed?",
            answer: (
              <>
                <p>
                  Yes, you can add or remove items from your order until it is
                  processed. Once the order is processed, changes to items in
                  the order won’t be possible. If you need to make adjustments,
                  please open a ticket as soon as possible after placing your
                  order, and our team will be happy to assist with any
                  modifications.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "Authenticity and Quality Control",
    subMenu: [
      {
        option: "Authenticity Related",
        subMenu: [
          {
            question: "Are your products brand new and authentic?",
            answer: (
              <>
                <p>
                  Yes, all of our products are brand new and verified authentic.
                  Each item undergoes a thorough 7-step authenticity and quality
                  check process before it is shipped out to you. Our commitment
                  to authenticity means every product is carefully examined to
                  meet high standards, so you can trust the quality and origin
                  of your purchase.
                </p>
              </>
            ),
          },
          {
            question: "What is your product authentication process?",
            answer: (
              <>
                <p>
                  Our authentication process is a rigorous, multi-step journey.
                  First, our team of trained authenticators, with years of
                  experience, inspects each sneaker for condition, ensuring it’s
                  brand new. Packaging is then verified for accuracy, including
                  box details and accessories. We also employ digital
                  authentication, comparing each sneaker to our extensive
                  database of verified images, and use AI tools and machine
                  learning to bolster our checks. Finally, we perform an in-hand
                  quality inspection to screen for any potential defects or
                  inconsistencies. Should a customer ever have concerns about
                  authenticity, we offer a grievance redressal policy, including
                  a refund if the item is proven inauthentic. Our goal is to
                  provide only the best, ensuring your purchase is genuine and
                  flawless.
                </p>
              </>
            ),
          },
          {
            question: "How can I verify the authenticity of my purchase?",
            answer: (
              <>
                <p>
                  All of our products are verified authentic, but we understand
                  the value of added peace of mind. Upon receiving your item,
                  you’re welcome to run an additional authenticity check using
                  any reputable online verification platform to cross-reference
                  and confirm authenticity, which can provide you with a
                  certificate as well.
                </p>
              </>
            ),
          },
          {
            question: "What happens to my order if an item fails verification?",
            answer: (
              <>
                <p>
                  Though rare, if an item were to fail our authenticity check,
                  we would immediately offer you a full refund or provide a
                  replacement pair. Additionally, we would offer substantial
                  compensation for the inconvenience, ensuring you’re more than
                  satisfied with the resolution.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Quality-Related",
        subMenu: [
          {
            question: "What is your quality control process?",
            answer: (
              <>
                <p>
                  Our quality control process is as meticulous as our
                  authentication. Each item goes through a multi-step check to
                  ensure it meets our high standards. Our team of trained
                  authenticators inspects every sneaker for condition,
                  confirming that it’s brand new and directly sourced from
                  trusted suppliers. We then verify the packaging, checking box
                  details, model tags, and accessories, followed by a digital
                  authentication using our extensive database of authentic
                  sneakers. Additionally, we use AI-driven tools and machine
                  learning to standardize and enhance our quality checks.
                  Finally, each sneaker is physically examined for any defects,
                  ensuring it meets HypeFly’s standards for quality. This way,
                  we guarantee that you receive a flawless, authentic product.
                </p>
              </>
            ),
          },
          {
            question:
              "What should I do if I receive an item with quality issues or defects?",
            answer: (
              <>
                <p>
                  If you receive an item that doesn’t meet your expectations due
                  to quality issues or defects, please reach out to us right
                  away. Simply open a support ticket with details of the issue,
                  and our team will be there to help. We’ll guide you through
                  the return or replacement process and ensure the matter is
                  resolved promptly. Customer satisfaction is our priority, and
                  we’re committed to making things right.
                </p>
              </>
            ),
          },
          {
            question:
              "How do I report a quality issue after receiving my item?",
            answer: (
              <>
                <p>
                  To report a quality issue, please open a ticket and contact us
                  as soon as possible. Share details about the defect, and our
                  team will review it and get back to you quickly. We’ll work
                  closely with you to resolve the matter to your satisfaction,
                  whether that means a replacement, a repair, or another
                  suitable solution.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "Shipping and Delivery Timelines",
    subMenu: [
      {
        option: "Shipping Timelines and Delivery Related",
        subMenu: [
          {
            question: "My order hasn't shipped yet; what should I do?",
            answer: (
              <>
                <p>
                  Most of our orders are dispatched within 3-5 working days. If
                  your order hasn’t shipped within this timeframe, please don’t
                  hesitate to reach out to us, and we’ll ensure it’s expedited.
                  For items that are sourced specifically on order, such as some
                  limited-edition products, the dispatch time is indicated on
                  the product page—typically 18-20 or 24-25 working days. If the
                  specified timeframe has passed, feel free to open a ticket,
                  and our team will help get things moving as quickly as
                  possible. We’re here to keep you updated every step of the
                  way!
                </p>
              </>
            ),
          },
          {
            question: "How long does it take to dispatch orders?",
            answer: (
              <>
                <p>
                  Most orders are dispatched within 3-5 working days. However,
                  for sourced items, the dispatch times vary from 18-20 working
                  days for general sourced products and 24-25 working days for
                  select items like Stanley mugs and Ray-Ban smart glasses.
                </p>
              </>
            ),
          },
          {
            question:
              "What is the delivery time once an order has been shipped?",
            answer: (
              <>
                <p>
                  Once your order has been dispatched, it typically takes 3-5
                  working days to reach you. If you’re in a more remote area,
                  delivery might take slightly longer, around 6-7 working days.
                </p>
              </>
            ),
          },
          {
            question: "Which shipping carrier do you use?",
            answer: (
              <>
                <p>
                  Our primary shipping partner is Shree Tirupati Courier. For
                  locations outside their service area, we use alternative
                  couriers like DTDC, FedEx, and Delhivery to ensure your order
                  reaches you wherever you are.
                </p>
              </>
            ),
          },
          {
            question:
              "The product I ordered indicates a delivery time of 18-20/22-24 working days; how long will it take?",
            answer: (
              <>
                <p>
                  For items with longer delivery times listed, they will be
                  dispatched within the indicated time frame of 18-20 or 22-24
                  working days, as noted on the product page. This means your
                  order will leave our warehouse within that period and then be
                  delivered shortly after.
                </p>
              </>
            ),
          },
          {
            question: "Why do certain products have longer delivery times?",
            answer: (
              <>
                <p>
                  Some of our products are sourced specifically on order, which
                  means they are procured internationally and brought in just
                  for you. Due to the longer sourcing and shipping times
                  involved, these items require a bit more patience, but we
                  assure you it’s worth the wait!
                </p>
              </>
            ),
          },
          {
            question: "How does your shipping procedure work?",
            answer: (
              <>
                <p>
                  Once your order is processed, you’ll receive an “Order
                  Processed” email. As soon as it’s shipped, we’ll send you
                  another email with tracking details so you can follow your
                  order’s journey to your doorstep. We’re committed to keeping
                  you informed at each step.
                </p>
              </>
            ),
          },
          {
            question: "Why did my order arrive in multiple shipments?",
            answer: (
              <>
                <p>
                  For orders containing multiple items, each product is sourced
                  and dispatched individually to avoid delays. As a result, you
                  may receive separate shipments. We’ll send you a tracking
                  number for each shipment via email. If you have questions or
                  need further assistance, please don’t hesitate to open a
                  support ticket—we’re here to help!
                </p>
              </>
            ),
          },
          {
            question: "What do you mean by working days?",
            answer: (
              <>
                <p>
                  When we refer to “working days,” we mean weekdays from Monday
                  to Friday. Saturdays, Sundays, and public holidays are not
                  considered working days and won’t count toward the processing
                  or shipping timelines.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Express Delivery Related",
        subMenu: [
          {
            question:
              "What is the estimated delivery time for express shipping?",
            answer: (
              <>
                <p>
                  Express orders are dispatched within 48 working hours (2
                  working days), excluding weekends and public holidays. This
                  means that once you place an express order, it’ll be on its
                  way within this timeframe. We’re committed to delivering your
                  items as swiftly as possible!
                </p>
              </>
            ),
          },
          {
            question: "Do you offer same-day or next-day delivery?",
            answer: (
              <>
                <p>
                  Yes, for certain products and locations in Mumbai and Delhi,
                  we may be able to arrange same-day or next-day delivery. To
                  check if this is available for your order, please open a
                  support ticket or DM us on Instagram at{" "}
                  <a
                    href="https://instagram.com/hypefly.in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    @hypefly.in
                  </a>{" "}
                  with your requirements, and we’ll do our best to assist.
                </p>
              </>
            ),
          },
          {
            question:
              "Is there a cost difference between express and standard delivery?",
            answer: (
              <>
                <p>
                  Yes, there is a difference in cost. Standard delivery is INR
                  299 for orders below INR 24,999, after which it’s free.
                  Express delivery, which offers quicker dispatch, is INR 1499
                  per order.
                </p>
              </>
            ),
          },
          {
            question:
              "Are there different tracking options for express and standard deliveries?",
            answer: (
              <>
                <p>
                  No, tracking works the same for both express and standard
                  deliveries. Once your order is shipped, you’ll receive an
                  email with tracking information. If you have trouble tracking
                  your order, please open a support ticket, and we’ll be glad to
                  assist.
                </p>
              </>
            ),
          },
          {
            question: "Can I track my order regardless of the delivery method?",
            answer: (
              <>
                <p>
                  Absolutely! Regardless of your delivery choice, you’ll receive
                  a shipping confirmation email with tracking information. You
                  can track your order directly from that email. If you need any
                  help with tracking, feel free to reach out.
                </p>
              </>
            ),
          },
          {
            question:
              "Can I upgrade from standard to express delivery after placing my order?",
            answer: (
              <>
                <p>
                  Yes, you can request an upgrade by opening a support ticket.
                  If it’s feasible, we’ll update your order to express shipping
                  and expedite it accordingly. Just let us know, and we’ll do
                  our best to accommodate your request.
                </p>
              </>
            ),
          },
          {
            question:
              "What to do if I opted for express shipping but my order was delayed?",
            answer: (
              <>
                <p>
                  We’re truly sorry if there’s any delay with your express
                  order. While we do our best to meet timelines, occasional
                  delays may occur due to circumstances outside our control. If
                  this happens, we’ll refund the express shipping fee
                  immediately and ensure that your order is prioritized for
                  delivery as quickly as possible.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Order Delayed",
        subMenu: [
          {
            question:
              "What should I do if my order is delayed beyond the estimated delivery date?",
            answer: (
              <>
                <p>
                  If your order hasn’t been dispatched within the expected
                  delivery timeframe—3-5 working days for standard orders or
                  18-20/24-25 working days for sourced items—please open a
                  support ticket, and we’ll ensure your order is expedited as
                  soon as possible. We’re here to keep things moving smoothly
                  and provide updates along the way.
                </p>
              </>
            ),
          },
          {
            question:
              "What happens if I miss the delivery attempt for my package?",
            answer: (
              <>
                <p>
                  No worries! The courier will automatically make another
                  attempt to deliver your package. If you’re concerned or would
                  like us to help expedite the re-attempt, just open a support
                  ticket, and we’ll gladly coordinate with the courier on your
                  behalf to prioritize the delivery.
                </p>
              </>
            ),
          },
          {
            question:
              "Are there any delivery restrictions or areas where you don’t deliver?",
            answer: (
              <>
                <p>
                  We deliver pan-India! For remote pin codes, we may need to use
                  an alternate courier, which could extend the delivery time
                  slightly. Typically, most areas receive deliveries within 3-5
                  working days post-dispatch, while remote areas may take around
                  6-7 working days. Regardless of your location, we’re committed
                  to getting your order to you.
                </p>
              </>
            ),
          },
          {
            question:
              "Can I change my delivery address after my order has shipped?",
            answer: (
              <>
                <p>
                  Once an order has been shipped and is with the courier, we’re
                  unable to make changes to the delivery address. However, if
                  your order hasn’t been dispatched yet, you can still update
                  all personal and address details by opening a support ticket.
                  We’ll help ensure your information is accurate before
                  shipping.
                </p>
              </>
            ),
          },
          {
            question: "Will I be notified if there is a delay in my delivery?",
            answer: (
              <>
                <p>
                  Yes, if there’s any delay with your delivery, we’ll notify you
                  via email to keep you informed and up to date. We’re committed
                  to providing timely updates every step of the way.
                </p>
              </>
            ),
          },
          {
            question:
              "How can I check the delivery status if I placed my order as a guest?",
            answer: (
              <>
                <p>
                  Whether you placed your order as a guest or with an account,
                  we’ll keep you updated via email notifications. You’ll receive
                  updates when your order is sourced and again when it’s
                  shipped, along with tracking details. You can monitor your
                  order status through these emails. If you decide to create an
                  account using the same email ID, your order history will sync
                  automatically, allowing you to track everything in one place.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "18-20 Days Sourced Orders Related",
    subMenu: [
      {
        option: "FAQs regarding orders with 18-20 dispatch time",
        subMenu: [
          {
            question: "How can I tell if my order is a sourced item?",
            answer: (
              <>
                <p>
                  If your item is a sourced product, this will be indicated on
                  the product page itself, just below the product name. You’ll
                  see a note stating “Ships in 18-20 working days” or “Ships in
                  24-25 working days,” depending on the item. If there’s no such
                  note, it means the product is ready for dispatch and will ship
                  within 3-5 working days.
                </p>
              </>
            ),
          },
          {
            question:
              "What does it mean if my order is sourced or has a longer shipping timeline?",
            answer: (
              <>
                <p>
                  A sourced item is one that we don’t keep in stock regularly
                  and is specially arranged upon receiving your order. Once your
                  order is placed, we coordinate with our vendors to secure the
                  product, which results in a longer shipping timeframe.
                </p>
              </>
            ),
          },
          {
            question: "How long does it take for a sourced order to arrive?",
            answer: (
              <>
                <p>
                  For sourced orders, dispatch from our warehouse takes either
                  18-20 or 24-25 working days, as mentioned on the product page.
                  Once dispatched, delivery to your address usually takes an
                  additional 3-5 working days, depending on your location.
                </p>
              </>
            ),
          },
          {
            question: "How can I track the status of a sourced order?",
            answer: (
              <>
                <p>
                  Once your order is placed, you’ll receive an order
                  confirmation email, followed by updates as your order
                  progresses. Throughout the process, we’ll send you email
                  updates on its status. When the item arrives at our warehouse,
                  we’ll send an “Order Shipped” email with tracking details as
                  it’s dispatched to your address.
                </p>
              </>
            ),
          },
          {
            question: "Can I expedite shipping on sourced items?",
            answer: (
              <>
                <p>
                  Expedited shipping isn’t available for sourced items due to
                  the additional time required to arrange them specially from
                  our suppliers.
                </p>
              </>
            ),
          },
          {
            question: "What should I do if my sourced order arrives damaged?",
            answer: (
              <>
                <p>
                  If your sourced order arrives damaged, please contact us
                  immediately. We’ll arrange for you to share images with our
                  team to assess the issue, and if confirmed, we’ll provide a
                  replacement or issue a refund based on your preference.
                </p>
              </>
            ),
          },
          {
            question: "What happens if a sourced order is delayed?",
            answer: (
              <>
                <p>
                  If there’s a delay beyond the mentioned timeline, we’ll keep
                  you updated on the progress via email. You’re also welcome to
                  reach out to us directly for more specific information, and
                  we’ll prioritize getting the order to you as soon as possible.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Shipping Costs / Customs and Payment Related",
        subMenu: [
          {
            question: "Are there additional shipping fees for sourced items?",
            answer: (
              <>
                <p>
                  No, sourced items do not incur any additional shipping fees.
                  The shipping cost for sourced items is the same as for any
                  other order.
                </p>
              </>
            ),
          },
          {
            question:
              "Will I be notified of any customs or import issues with my international order?",
            answer: (
              <>
                <p>
                  No need to worry about customs or import fees—once your order
                  is placed and paid for, the total amount is final. We handle
                  all customs and import processes on our end, and your order
                  will ship domestically from our Mumbai or Delhi warehouse. If
                  you need to update your delivery address after placing the
                  order, please open a ticket to reach out to us.
                </p>
              </>
            ),
          },
          {
            question:
              "Am I responsible for customs duties or taxes on sourced orders?",
            answer: (
              <>
                <p>
                  No, you are not responsible for any customs duties or taxes on
                  sourced items. All additional costs are covered by us, and
                  there are no extra fees beyond the amount you paid at
                  checkout.
                </p>
              </>
            ),
          },
          {
            question: "From where will my order be shipped?",
            answer: (
              <>
                <p>
                  Your order will be shipped from either our Mumbai or Delhi
                  warehouse.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Editing and Canceling Sourced Orders",
        subMenu: [
          {
            question:
              "Are there any return or exchange restrictions for sourced items?",
            answer: (
              <>
                <p>
                  The return and exchange policy remains the same for all
                  orders, whether sourced or not. You can review our return
                  policy{" "}
                  <a
                    href="https://hypefly.co.in/policies/return-policy"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    here
                  </a>
                  . If you have any additional questions, please don’t hesitate
                  to open a support ticket, and we’ll be happy to assist.
                </p>
              </>
            ),
          },
          {
            question: "Can I cancel a sourced order?",
            answer: (
              <>
                <p>
                  Orders can be edited or canceled for store credit within the
                  first 3-4 hours after placement, during which you will receive
                  an “Order Placed” email confirmation. Once an order is
                  processed, no changes or cancellations are possible. If you
                  need further assistance, please open a support ticket, and our
                  team will assist you promptly.
                </p>
              </>
            ),
          },
          {
            question: "Do sourced orders get delayed sometimes?",
            answer: (
              <>
                <p>
                  Occasionally, sourced orders may experience a slight delay of
                  2-3 working days, often due to extended public holidays or
                  weekends when couriers do not operate. While we strive to
                  fulfill orders within the estimated timeframe, some delays may
                  occur due to factors beyond our control.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "Product Info and Sizing Guides",
    subMenu: [
      {
        option: "Product Related",
        subMenu: [
          {
            question: "Can you help me choose the right pair to purchase?",
            answer: (
              <>
                <p>
                  Absolutely! We’re always here to help you find the perfect
                  pair. You can reach out to us directly via DM on Instagram at
                  <a
                    href="https://instagram.com/hypefly.in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    @hypefly.in
                  </a>{" "}
                  to chat with a member of our customer service team who will
                  provide personal assistance. They can help guide you through
                  options based on your style, and preferences, and even offer
                  sizing recommendations. You can also open a support ticket
                  here for additional help if needed. We’ll make sure you feel
                  confident about your choice!
                </p>
              </>
            ),
          },
          {
            question: "Where can I find more details about a product?",
            answer: (
              <>
                <p>
                  We provide an extensive description of each product right
                  below the images on its product page, where you’ll find key
                  details like materials, features, and design elements. If you
                  still have any questions or need further clarification, please
                  feel free to reach out to us—either by opening a ticket or
                  sending a DM on Instagram. We’re here to answer any and all
                  questions!
                </p>
              </>
            ),
          },
          {
            question: "I have questions about a product; whom can I contact?",
            answer: (
              <>
                <p>
                  If you have any questions about a product, you’re welcome to
                  contact us in one of two ways. You can either open a support
                  ticket here, and our team will get back to you as quickly as
                  possible, or you can DM us on Instagram at{" "}
                  <a
                    href="https://instagram.com/hypefly.in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    @hypefly.in
                  </a>{" "}
                  for an immediate response. We’re always ready to help!
                </p>
              </>
            ),
          },
          {
            question: "What is the condition of items sold on HypeFly?",
            answer: (
              <>
                <p>
                  All items sold on HypeFly are brand new and verified
                  authentic. Each product goes through a thorough quality and
                  authenticity check before shipping to ensure you receive it in
                  pristine condition. We source our products from trusted
                  suppliers and guarantee that every item is in perfect
                  condition with original packaging.
                </p>
              </>
            ),
          },
          {
            question:
              "Do products come with the original box and all accessories?",
            answer: (
              <>
                <p>
                  Yes, each product comes in its original box, complete with all
                  tags, laces, and any other accessories that were included by
                  the brand. We ensure that everything is in perfect condition
                  so that you receive the full, authentic experience.
                </p>
              </>
            ),
          },
          {
            question:
              "If a product is out of stock, will it be restocked soon?",
            answer: (
              <>
                <p>
                  If a product is out of stock, it may not be restocked, as many
                  of our items are limited editions or high-demand releases.
                  However, you’re welcome to DM us on Instagram or open a ticket
                  here, and we’ll do our best to see if restocking is possible
                  or if we can source the item just for you. We’re here to help
                  you find exactly what you’re looking for!
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Sizing Related",
        subMenu: [
          {
            question: "Do you offer a sizing guide for sneakers?",
            answer: (
              <>
                <p>
                  Yes, we do! Each sneaker product page includes a detailed
                  sizing guide specifically tailored for that product. This
                  guide can help you find the right fit based on the brand and
                  style. If you need further assistance, please don’t hesitate
                  to open a support ticket or send us a DM on Instagram—we’re
                  here to help make sure you find the perfect fit.
                </p>
              </>
            ),
          },
          {
            question: "Do you offer a sizing guide for apparel?",
            answer: (
              <>
                <p>
                  Yes, all our apparel items also come with a sizing guide on
                  the product page to help you choose the best size. This guide
                  provides measurements and fit recommendations based on the
                  brand. If you’re still unsure, feel free to reach out by
                  opening a ticket or sending us a DM, and our team will be
                  happy to assist.
                </p>
              </>
            ),
          },
          {
            question: "What should I do if I’m still unsure of my size?",
            answer: (
              <>
                <p>
                  If you’re still uncertain about sizing, we’re here to help!
                  Simply open a support ticket or DM us on Instagram, and our
                  team will guide you through finding the best fit for your
                  needs.
                </p>
              </>
            ),
          },
          {
            question: "Can you provide a size recommendation based on fit?",
            answer: (
              <>
                <p>
                  Yes, we’d be happy to help recommend a size based on fit. For
                  most of our products, going “true to size” (your usual size)
                  works well, especially for Jordans, Dunks, and New Balance
                  sneakers. For Yeezys and On Running shoes, we typically
                  suggest going half a size up for the best comfort. Reach out
                  by opening a ticket, and we’ll provide personalized guidance
                  to help you find the perfect fit.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "Account Management",
    subMenu: [
      {
        option: "Account Log-In Related",
        subMenu: [
          {
            question: "How can I reset my password if I've forgotten it?",
            answer: (
              <>
                <p>
                  If you’ve forgotten your password, don’t worry! Just go to the
                  &quot;My Account&quot; section at the top left of our website,
                  click &quot;Forgot Password&quot;, and we’ll send you a reset
                  link to your registered email. If you encounter any issues or
                  need further assistance, please don’t hesitate to open a
                  support ticket, and our customer service team will be happy to
                  help you through the process.
                </p>
              </>
            ),
          },
          {
            question: "What should I do if I cannot log into my account?",
            answer: (
              <>
                <p>
                  If you’re unable to log in, try resetting your password and
                  double-checking your login details. Ensure your internet
                  connection is stable, and you may also try logging in through
                  a new browser tab. If none of these steps work, please open a
                  ticket, and our team will assist in resetting your account
                  details.
                </p>
              </>
            ),
          },
          {
            question: "Why is my order not showing up in my account?",
            answer: (
              <>
                <p>
                  If your order isn’t appearing in your account, please open a
                  support ticket, and we’ll work on syncing your order history
                  with your account. Our team will ensure everything is up to
                  date and visible to you.
                </p>
              </>
            ),
          },
          {
            question: "How do I create an account on your website?",
            answer: (
              <>
                <p>
                  To create an account, go to the top right of our website,
                  enter your email, and click &quot;Sign Up.&quot; Follow the
                  prompts, and you’ll be all set!
                </p>
              </>
            ),
          },
          {
            question:
              "Can I update my email address or phone number in my account?",
            answer: (
              <>
                <p>
                  Yes, absolutely! Just follow the same process as before—log
                  into your account and update your information. If you need any
                  help, our support team is here to assist.
                </p>
              </>
            ),
          },
          {
            question: "How do I view my order history and past purchases?",
            answer: (
              <>
                <p>
                  To view your order history, log into your account by clicking
                  &quot;My Account&quot; at the top left of the website, then
                  select &quot;Orders.&quot; If you have any difficulty
                  accessing your order history, please open a ticket, and we’ll
                  gladly assist.
                </p>
              </>
            ),
          },
          {
            question: "Can I place an order without creating an account?",
            answer: (
              <>
                <p>
                  Yes, you can place an order as a guest. We’ll still send you
                  order confirmations and updates via email. Later, if you
                  choose to create an account with the same email, all your past
                  orders will sync automatically, giving you easy access to your
                  purchase history.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Guest Checkout FAQs",
        subMenu: [
          {
            question:
              "What are the benefits of creating an account versus using guest checkout?",
            answer: (
              <>
                <p>
                  Creating an account with us offers several benefits! You can
                  easily check your order status directly on our website, review
                  your order history, and save addresses for faster checkout
                  next time. Plus, as an account holder, you’ll get early access
                  to all our sales, exclusive deals, and flash sales via our
                  newsletters. It’s a great way to stay connected and enjoy the
                  best offers!
                </p>
              </>
            ),
          },
          {
            question:
              "Can I save my information for future orders if I use guest checkout?",
            answer: (
              <>
                <p>
                  Yes, you can still save your shipping information for future
                  orders. If you decide to create an account later, your saved
                  addresses will be stored under your profile, making future
                  checkouts even faster and easier.
                </p>
              </>
            ),
          },
          {
            question: "How do I view past orders if I checked out as a guest?",
            answer: (
              <>
                <p>
                  If you checked out as a guest, you can create an account using
                  the same email address you used for your purchase, and all
                  your past orders will sync automatically to your account. If
                  you run into any trouble, simply open a support ticket, and
                  our team will be happy to help you access your order history.
                </p>
              </>
            ),
          },
          {
            question: "Can I convert my guest checkout to an account?",
            answer: (
              <>
                <p>
                  Absolutely! Just sign up with the same email address used for
                  your guest checkout, and all your previous orders will appear
                  in your new account. This allows you to manage your orders
                  more conveniently and access additional benefits available to
                  account holders.
                </p>
              </>
            ),
          },
          {
            question: "Will I receive order updates if I check out as a guest?",
            answer: (
              <>
                <p>
                  Yes, even if you check out as a guest, you’ll still receive
                  all order updates via email. We’ll keep you informed at each
                  stage, from order confirmation to shipping and delivery.
                </p>
              </>
            ),
          },
          {
            question: "How do I track my order if I checked out as a guest?",
            answer: (
              <>
                <p>
                  You’ll receive regular updates via email, including tracking
                  information once your order is shipped. Each email will
                  include a link or tracking details, allowing you to easily
                  monitor your order’s progress until it arrives.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "Promo Codes and Store Credit",
    subMenu: [
      {
        option: "Promo Code Related",
        subMenu: [
          {
            question: "How do I apply a promo code to my order?",
            answer: (
              <>
                <p>
                  To apply a promo code, simply enter it in the &quot;Apply
                  Coupon&quot; field during checkout. If you experience any
                  issues applying the code, please feel free to open a support
                  ticket, and we’ll be happy to help.
                </p>
              </>
            ),
          },
          {
            question: "Why isn't my promo code working at checkout?",
            answer: (
              <>
                <p>
                  If your promo code isn’t working, it might be due to a few
                  reasons:
                </p>
                <p>
                  - Double-check the capitalization (some codes require all
                  caps, no caps, or specific formatting).
                </p>
                <p>
                  - Ensure that the code hasn’t expired, as older codes may no
                  longer be active. If you’ve verified these and the code still
                  isn’t working, please open a ticket, and our team will resolve
                  it for you immediately.
                </p>
              </>
            ),
          },
          {
            question: "Can I use multiple promo codes on a single order?",
            answer: (
              <>
                <p>
                  No, only one promo code can be applied per order. Promo codes
                  do not stack up.
                </p>
              </>
            ),
          },
          {
            question: "Can I apply a promo code after placing an order?",
            answer: (
              <>
                <p>
                  Unfortunately, once an order is placed and confirmed, we’re
                  unable to apply promo codes retroactively. All discounts need
                  to be applied during checkout.
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Gifting",
        subMenu: [
          {
            question: "Do you offer gift packing or wrapping services?",
            answer: (
              <>
                <p>
                  While we don’t typically offer gift wrapping, we’re more than
                  happy to make exceptions for you! Just open a support ticket
                  or DM us on Instagram, let us know your wrapping preferences,
                  and we’ll take care of it. Whether it’s a beautifully wrapped
                  box or a handwritten card, we’re here to make your gift extra
                  special.
                </p>
              </>
            ),
          },
          {
            question: "How can I add gift wrapping to my order?",
            answer: (
              <>
                <p>
                  Simply open a support ticket or reach out to us via Instagram
                  DM with your gift-wrapping request, and we’ll make it happen
                  for you!
                </p>
              </>
            ),
          },
          {
            question: "Are there additional charges for gift wrapping?",
            answer: (
              <>
                <p>
                  No, we don’t charge extra for gift wrapping. We believe in
                  spreading happiness, and we’re glad to help you make your gift
                  memorable.
                </p>
              </>
            ),
          },
          {
            question:
              "Can I include a personalized message with the gift wrapping?",
            answer: (
              <>
                <p>
                  Absolutely! When you open a ticket to request gift wrapping,
                  feel free to include your personalized message, and we’ll add
                  it to the package. We’re here to make your gift-giving
                  experience as thoughtful as possible!
                </p>
              </>
            ),
          },
        ],
      },
      {
        option: "Store Credit-Related",
        subMenu: [
          {
            question: "What is store credit?",
            answer: (
              <>
                <p>
                  Store credit is a credit balance issued by us, which can be
                  used exclusively on our website. If an order is canceled, or
                  modified, or if we’ve issued store credit as a resolution,
                  you’ll receive a unique coupon code equivalent to the amount.
                  This credit can be used partially or in full across multiple
                  purchases. For example, if you’d like to use only part of your
                  store credit, the remaining balance will stay available for
                  future orders. If your purchase exceeds your store credit, you
                  can simply pay the additional amount at checkout.
                </p>
              </>
            ),
          },
          {
            question: "How will I receive store credit?",
            answer: (
              <>
                <p>
                  We’ll send you a unique coupon code by email with the store
                  credit amount. You can use this code during checkout on your
                  next purchase. If you have any questions about applying, we’re
                  here to help!
                </p>
              </>
            ),
          },
          {
            question: "How do I apply store credit to a purchase?",
            answer: (
              <>
                <p>
                  At checkout, you’ll see an “Apply Coupon” field. Simply enter
                  your store credit code there, and the amount will be applied
                  to your purchase. If you experience any issues or have
                  questions, feel free to reach out to us via a support
                  ticket—we’re happy to assist!
                </p>
              </>
            ),
          },
          {
            question: "Can I combine store credit with other payment methods?",
            answer: (
              <>
                <p>
                  Yes, absolutely! You can use your store credit alongside other
                  payment methods. If the order total exceeds your store credit
                  amount, you can cover the remaining balance using any other
                  payment option available at checkout.
                </p>
              </>
            ),
          },
          {
            question: "Is store credit refundable or exchangeable for cash?",
            answer: (
              <>
                <p>
                  No, store credit cannot be refunded or exchanged for cash. It
                  can only be used for purchases on our website.
                </p>
              </>
            ),
          },
          {
            question: "Does store credit expire if unused?",
            answer: (
              <>
                <p>
                  Yes, store credit does have a validity period. We’ll specify
                  the expiration date in the email when we send you the store
                  credit code, so you’ll have plenty of time to use it.
                </p>
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    option: "Other",
    subMenu: [
      {
        option: "No, my request is not specified above.",
      },
    ],
  },
];
