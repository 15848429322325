import React, { useReducer } from "react";
import { cartReducer, State, initialState } from "./cart.reducer";
import { Item, getItem } from "./cart.utils";
import { useLocalStorage } from "../../utils/use-local-storage";

export const cartContext = React.createContext(undefined);

cartContext.displayName = "CartContext";

export const useCart = () => {
  const context = React.useContext(cartContext);
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`);
  }
  return context;
};

export const CartProvider = (props) => {
  const [savedCart, saveCart] = useLocalStorage(
    `chawkbazar-cart`,
    JSON.stringify(initialState)
  );
  const [state, dispatch] = useReducer(cartReducer, JSON.parse(savedCart));

  React.useEffect(() => {
    saveCart(JSON.stringify(state));
  }, [state, saveCart]);

  // const inStock=()=>{}
  const value = React.useMemo(() => {
    const addItemToCart = (item, quantity) =>
      dispatch({ type: "ADD_ITEM_WITH_QUANTITY", item, quantity });

    const removeItemFromCart = (id, size) =>
      dispatch({ type: "REMOVE_ITEM_OR_QUANTITY", id, size });
    const clearItemFromCart = (id, size) =>
      dispatch({ type: "REMOVE_ITEM", id, size });
    const isInCart = (id) => !!getItem(state.items, id);
    const getItemFromCart = (id) => getItem(state.items, id);
    const updateShippingCost = (value) =>
      dispatch({ type: "UPDATE_SHIPPING_COST", value: value });
    const updatePromoValue = (value, code) =>
      dispatch({ type: "UPDATE_PROMO_VALUE", value: value, code: code });
    const resetCart = () => dispatch({ type: "RESET_CART" });
    const updateNotes = (value) =>
      dispatch({ type: "UPDATE_NOTES_VALUE", value: value });

    const updateNotesChecked = (value) =>
      dispatch({ type: "UPDATE_NOTES_CHECKED", value: value });

    const updateGiftWrap = (value) =>
      dispatch({ type: "UPDATE_GIFT_WRAP", value: value });

    return {
      ...state,
      addItemToCart,
      removeItemFromCart,
      clearItemFromCart,
      getItemFromCart,
      isInCart,
      updateShippingCost,
      updatePromoValue,
      resetCart,
      updateNotes,
      updateNotesChecked,
      updateGiftWrap,
    };
  }, [state]);
  return <cartContext.Provider value={value} {...props} />;
};
