import { gql } from "./graphql";

export const CREATE_SIZE_REQUEST = gql`
  mutation sizeRequest(
    $productName: String!
    $customerName: String!
    $email: String!
    $insta: String
    $phone: String
    $size: String!
  ) {
    createSizeRequest(
      data: {
        productName: $productName
        customerName: $customerName
        email: $email
        insta: $insta
        phone: $phone
        size: $size
      }
    ) {
      data {
        attributes {
          productName
          customerName
          email
          insta
          phone
          size
        }
      }
    }
  }
`;
