import Layout from "@/components/layout/layout";
import { LazyMotion, domAnimation } from "@/lib/framer-motion";
import { DefaultSeo } from "@/utils/default-seo";
import GraphqlProvider from "@/lib/provider";
import { SessionProvider } from "next-auth/react";
import { ManagedUIContext } from "@/contexts/ui.context";
import ManagedDrawer from "@/components/common/drawer/managed-drawer";
import ManagedModal from "@/components/common/modal/managed-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/custom-plugins.css";
import "../styles/tailwind.css";
import "../styles/slick.css";
import "../styles/scrollbar.css";
import { useEffect } from "react";
import { useRouter } from "next/router";
import * as fbq from "../lib/fpixel";
import { GoogleAnalytics } from "@next/third-parties/google";
import Script from "next/script";

function MyApp({ Component, pageProps: { session, ...pageProps }, router }) {
  const routerEl = useRouter();

  useEffect(() => {
    fbq.pageview();
    const handleRouteChange = () => {
      fbq.pageview();
    };
    routerEl.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      routerEl.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [routerEl.events]);

  return (
    <SessionProvider session={session}>
      <ManagedUIContext>
        <GraphqlProvider>
          <LazyMotion features={domAnimation}>
            <DefaultSeo />
            <Layout pageProps={pageProps}>
              <Component {...pageProps} key={router.route} />
              <GoogleAnalytics
                gaId={`${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
                strategy="lazyOnload"
                async
              />
              <ManagedDrawer />
              <ManagedModal />
              <ToastContainer />
            </Layout>
          </LazyMotion>
          <Script
            id="fb-pixel"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
            !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "1499122534243088");
          `,
            }}
          />
        </GraphqlProvider>
      </ManagedUIContext>
    </SessionProvider>
  );
}

export default MyApp;
