import { gql } from "./graphql";
export const All_WAREHOUSES = gql`
  query warehouses {
    warehouses {
      data {
        id
        attributes {
          name
          awbStart
          awbCurrent
          total
        }
      }
    }
  }
`;
export const UPDATE_WAREHOUSE = gql`
  mutation updateWarehouse($id: ID!, $data: WarehouseInput!) {
    updateWarehouse(id: $id, data: $data) {
      data {
        id
        attributes {
          name
          awbStart
          awbCurrent
          total
        }
      }
    }
  }
`;
