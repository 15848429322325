import { gql } from "./graphql";
export const QUERY_ALL_TYPES_BY_COLLECTION = gql`
  query getProductTypesByCollection(
    $limit: Int!
    $filter: ProductTypeFiltersInput
    $sortBy: [String]
    $slug: String
  ) {
    productTypes(
      sort: $sortBy
      pagination: { limit: $limit }
      filters: $filter
    ) {
      data {
        id
        attributes {
          name
          products(
            pagination: { limit: $limit }
            filters: { productCollections: { slug: { eq: $slug } } }
          ) {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
