import { AnimatePresence, m } from "framer-motion";
import React, { useEffect, useState } from "react";
import { supportData } from "@/framework/data/support-data";
import FaqDropdown from "../ui/faq-drodpdown";
import ChatIcon from "../icons/chat-icon";
import ChevronDown from "../icons/chevron-down";
import Input from "../ui/input";
import TextArea from "../ui/text-area";
import Button from "../ui/button";
import ZohoTicketForm from "./zoho-ticket-form";
import Link from "next/link";
import InstagramOutline from "../icons/instagram-outline";
const containerVariants = {
  hidden: { height: 0 },
  visible: {
    height: "auto",
    transition: {
      duration: 0.3,
      ease: "easeInOut",
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
  exit: { height: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};
const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
  exit: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};
const faqVariants = {
  hidden: { height: 0 },
  visible: {
    height: "auto",
    transition: {
      duration: 0.3,
      ease: "easeInOut",
    },
  },
  exit: { height: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};
export default function SupportForm({ showInstaButton = false }) {
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedSubmenu, setSelectedSubmenu] = useState("");
  const [showTicket, setShowTicket] = useState(false);
  const [showTicketButton, setShowTicketButton] = useState(false);
  useEffect(() => {
    console.log(selectedReason);
    setSelectedSubmenu();
  }, [selectedReason]);
  useEffect(() => {
    if (selectedSubmenu) {
      setShowTicketButton(true);
    } else {
      setShowTicketButton(false);
    }
  }, [selectedSubmenu]);

  return (
    <div className="flex flex-col md:gap-10 gap-8 pt-4 lg:pt-0">
      <FloatingLabelSelect
        options={supportData?.map((row) => row?.option)}
        label="Please tell us why you're getting in touch"
        onSelect={(value) => setSelectedReason(value)}
      />
      <AnimatePresence>
        {selectedReason && (
          <m.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="w-full flex flex-col"
          >
            <FloatingLabelSelect
              options={supportData
                ?.find((row) => row?.option == selectedReason)
                ?.subMenu?.map((col) => col?.option)}
              label="Can you be more specific?"
              onSelect={setSelectedSubmenu}
              value={selectedSubmenu || ""}
            />
          </m.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {selectedSubmenu &&
          supportData
            ?.find((row) => row?.option == selectedReason)
            ?.subMenu?.find((col) => col?.option == selectedSubmenu)
            ?.subMenu && (
            <m.div
              variants={faqVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="w-full flex flex-col px-4 bg-[#F9F7F6] rounded-md overflow-hidden"
            >
              <h3 className="text-black font-bold text-lg mt-4">
                Is this what you are looking for?
              </h3>
              {supportData
                ?.find((row) => row?.option == selectedReason)
                ?.subMenu?.find((col) => col?.option == selectedSubmenu)
                ?.subMenu?.map((item, index) => (
                  <FaqDropdown
                    key={"faqSubMenu" + index}
                    title={item?.question}
                    initial={false}
                  >
                    {item?.answer}
                  </FaqDropdown>
                ))}
            </m.div>
          )}
      </AnimatePresence>
      <div className="flex md:flex-row flex-col gap-4 w-full h-fit md:items-center">
        {showTicketButton && (
          <div
            onClick={() => setShowTicket(true)}
            className="rounded-full border-[1px] bg-white hover:bg-gray-200 cursor-pointer border-black w-fit px-4 md:py-3 py-2 text-black font-semibold flex items-center justify-center gap-2 md:text-base text-sm"
          >
            <ChatIcon className="text-black w-8 h-8" />
            Create Support Ticket
          </div>
        )}
        {showInstaButton && showTicketButton && (
          <Link
            href=""
            target="_blank"
            className="rounded-full bg-black cursor-pointer w-fit px-4 md:py-3 py-2 text-white capitalize font-semibold flex items-center justify-start gap-2 md:text-base text-sm"
          >
            <InstagramOutline className="w-8 h-8 text-white" />
            chat with us on Instagram
          </Link>
        )}
      </div>

      <AnimatePresence>
        {showTicket && (
          <m.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="w-full flex flex-col"
          >
            <ZohoTicketForm selectedSubmenu={selectedSubmenu} />
          </m.div>
        )}
      </AnimatePresence>
    </div>
  );
}
const FloatingLabelSelect = ({ label, options, onSelect, value }) => {
  const [focused, setFocused] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleFocus = () => setFocused(true);
  const handleBlur = () => {
    if (!selectedValue) setFocused(false);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    onSelect && onSelect(value);
  };

  return (
    <m.div
      className={`relative w-full border-black bg-white ${
        selectedValue ? "border-b-[2px]" : "border-b-[1px]"
      }`}
      key={"secondary"}
      variants={itemVariants}
    >
      <label
        className={`pointer-events-none absolute text-[#333] transform -translate-y-1/2 transition-all duration-200 ease-in-out ${
          focused || selectedValue
            ? "-top-[6px] text-xs"
            : "top-1/2 text-sm md:text-base"
        }`}
      >
        {label}
      </label>
      <select
        className="select-input ring-offset-0 !ring-0 !outline-none !border-none !bg-white !px-0 py-2 w-full text-sm md:text-base focus:outline-0 focus:ring-0 focus:ring-offset-0 focus:border-0"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
      >
        <option disabled="" value=""></option>

        {options.map((option, index) => (
          <option key={index} value={option} className="pl-0">
            {option}
          </option>
        ))}
      </select>
    </m.div>
  );
};
