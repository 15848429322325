import React, { useRef } from "react";
import MobileMenu from "@/components/layout/header/mobile-menu";
import { useUI } from "@/contexts/ui.context";
import useScrollLock from "@/utils/use-scroll-lock";
import { AnimatePresence, m } from "@/lib/framer-motion";
import useOnClickOutside from "@/utils/use-click-outside";
export default function MenuDrawer() {
  const { closeSidebar, displaySidebar } = useUI();
  const ref = useRef(null);
  useOnClickOutside(ref, () => closeSidebar());
  useScrollLock(displaySidebar);

  return (
    // <Drawer
    //   placement={"left"}
    //   open={displaySidebar}
    //   onClose={closeSidebar}
    //   contentWrapperStyle={{ left: 0, maxWidth: "500px", width: "100%" }}
    //   {...motionProps}
    // >
    //   <MobileMenu />
    // </Drawer>

    <AnimatePresence mode="wait">
      {displaySidebar && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 100,
          }}
          exit={{ opacity: 0 }}
          className="fixed top-0 w-full h-full bg-black/50 flex items-center justify-start z-[999999]"
          key="overlay"
        >
          <m.div
            initial={{ x: "-100%", opacity: 100 }}
            animate={{ x: 0, opacity: 100 }}
            exit={{ x: "-100%", opacity: 100 }}
            transition={{ duration: 0.2 }}
            ref={ref}
            className="max-w-[500px] w-full bg-white h-full fixed top-0 z-[99999]"
            key="cart"
          >
            <MobileMenu />
          </m.div>
        </m.div>
      )}
    </AnimatePresence>
  );
}
