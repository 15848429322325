import Link from "next/link";
export default function AuthMenu({
  isAuthorized,
  href,
  className,
  btnProps,
  children,
}) {
  return isAuthorized ? (
    <Link href={href} className={className}>
      {btnProps.children}
    </Link>
  ) : (
    <button {...btnProps} className={className} />
  );
}
