import { gql } from "./graphql";
export const ORDER_FIELDS = gql`
  fragment OrderFields on Order {
    orderID
    firstName
    lastName
    email
    addressLineOne
    addressLineTwo
    country
    city
    state
    pincode
    mobile
    subTotal
    total
    paid
    successful
    sourced
    shipped
    deliveryDate
    refunded
    initiateRefund
    shippingMethod
    promoCode
    createdAt
    updatedAt
    paymentMethod
    tracking
    orderItems {
      id
      product {
        data {
          id
          attributes {
            name
            images {
              data {
                attributes {
                  url
                  name
                  alternativeText
                  formats
                }
              }
            }
          }
        }
      }
      title
      orderName
      price
      shipped
      delivered
      size
      quantity
    }
  }
`;
export const CREATE_ORDER = gql`
  ${ORDER_FIELDS}
  mutation createOrder(
    $orderID: String
    $firstName: String
    $lastName: String
    $email: String
    $addressLineOne: String
    $addressLineTwo: String
    $country: String
    $city: String
    $state: String
    $pincode: Long
    $mobile: Long
    $subTotal: Int
    $total: Int
    $paid: Boolean
    $successful: Boolean
    $sourced: Boolean
    $shipped: Boolean
    $refunded: Boolean
    $initiateRefund: Boolean
    $shippingMethod: String
    $promoCode: String
    $itemsString: String
    $orderItems: [ComponentOrderItemsOrderItemsInput]
    $publishedAt: DateTime
    $giftWrap: Boolean
    $notes: String
  ) {
    createOrder(
      data: {
        orderID: $orderID
        firstName: $firstName
        lastName: $lastName
        email: $email
        addressLineOne: $addressLineOne
        addressLineTwo: $addressLineTwo
        country: $country
        city: $city
        state: $state
        pincode: $pincode
        mobile: $mobile
        subTotal: $subTotal
        total: $total
        paid: $paid
        successful: $successful
        sourced: $sourced
        shipped: $shipped
        refunded: $refunded
        initiateRefund: $initiateRefund
        shippingMethod: $shippingMethod
        promoCode: $promoCode
        orderItems: $orderItems
        publishedAt: $publishedAt
        itemsString: $itemsString
        giftWrap: $giftWrap
        notes: $notes
      }
    ) {
      data {
        id
        attributes {
          ...OrderFields
        }
      }
    }
  }
`;
export const GET_ORDER = gql`
  ${ORDER_FIELDS}
  query getOrder($orderID: String!) {
    order(orderID: $orderID) {
      data {
        id
        attributes {
          ...OrderFields
        }
      }
    }
  }
`;
export const GET_ORDERS_BY_USER = gql`
  ${ORDER_FIELDS}
  query getOrdersByUser($email: String!, $limit: Int!) {
    orders(
      pagination: { limit: $limit }
      filters: { email: { eq: $email }, paid: { eq: true } }
    ) {
      data {
        id
        attributes {
          ...OrderFields
        }
      }
    }
  }
`;
export const UPDATE_ORDER = gql`
  ${ORDER_FIELDS}
  mutation updateOrder($data: OrderInput!, $id: ID!) {
    updateOrder(id: $id, data: $data) {
      data {
        attributes {
          ...OrderFields
        }
      }
    }
  }
`;
