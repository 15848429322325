import Link from "next/link";
import ChevronDown from "@/components/icons/chevron-down";
import ListMenu from "@/components/ui/list-menu";
import classNames from "classnames";
const HeaderMenu = ({ data, className }) => {
  return (
    <div
      className={classNames(
        `headerMenu flex w-full relative justify-center`,
        className
      )}
    >
      {data?.map((item) => (
        <div
          className={`menuItem group cursor-pointer py-7 ${
            item.subMenu ? "relative" : ""
          }`}
          key={item.id}
        >
          {item.path ? (
            <Link
              href={item.path}
              className={`relative inline-flex items-center px-4 py-1.5 2xl:text-base xl:text-base text-sm font-normal text-heading group-hover:text-black transition-all duration-300 ease ${
                item.label === "LIT SALE"
                  ? "group-hover:shadow-none"
                  : "group-hover:[text-shadow:_0.3px_0.3px_0_rgb(0_0_0_/_100%)]"
              }`}
            >
              <span
                className={`${
                  item.label === "LIT SALE"
                    ? "uppercase font-semibold"
                    : "capitalize"
                }`}
              >
                {item.label}
              </span>
              {(item?.columns || item.subMenu) && (
                <span className="opacity-50 text-sm w-4 ml-1 flex justify-end">
                  <ChevronDown className="transition duration-300 ease-in-out transform group-hover:-rotate-180" />
                </span>
              )}
            </Link>
          ) : (
            <div className="relative inline-flex items-center px-3 py-2 2xl:text-base xl:text-base text-sm font-normal text-heading xl:px-4 group-hover:text-black">
              {item.label}
              {(item?.columns || item.subMenu) && (
                <span className="opacity-50 ml-1 2xl:text-base xl:text-base text-sm mt-1 xl:mt-0.5 w-4 flex justify-end">
                  <ChevronDown className="transition duration-300 ease-in-out transform group-hover:-rotate-180" />
                </span>
              )}
            </div>
          )}

          {item?.subMenu && Array.isArray(item.subMenu) && (
            <div className="shrink-0 absolute invisible bg-white opacity-0 group-hover:visible subMenu flex flex-col left-0 group-hover:opacity-100 px-2 rounded-2xl shadow-menu">
              <ul className="py-1.5 2xl:text-base xl:text-base text-sm text-gray-700">
                {item.subMenu.map((menu, index) => {
                  const dept = 1;
                  const menuName = `sidebar-menu-${dept}-${index}`;

                  return (
                    <ListMenu
                      dept={dept}
                      data={menu}
                      hasSubMenu={menu.subMenu}
                      menuName={menuName}
                      key={menuName}
                      menuIndex={index}
                    />
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default HeaderMenu;
