import React from "react";
import Link from "next/link";
export default function TopBar() {
  return (
    <Link
      id="topBar"
      href={"/collections/the-lit-sale"}
      className="flex justify-center items-center hover:text-white/80 transition-all duration-300 ease text-white md:text-sm text-[10px] font-avenir font-medium text-center w-full bg-[#1A202C] lg:h-10 h-8"
    >
      THE LIT SALE IS HERE | SHOP THE BIGGEST SNEAKER SALE OF THE SEASON
    </Link>
  );
}
