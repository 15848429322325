import ChevronRight from "../icons/chevron-right";
import Link from "next/link";
import cn from "classnames";

const ListMenu = ({
  dept,
  data,
  hasSubMenu,
  hasMegaMenu,
  hasBrands,
  hasBanners,
  menuIndex,
}) => {
  return (
    <li className={cn(!hasMegaMenu ? "relative rounded group/list" : "")}>
      <Link
        href={data.path}
        className={`flex items-center 2xl:text-base xl:text-base text-sm py-[3.5px] px-2 hover:text-heading rounded bg-white ${
          data.label.toLowerCase() === "special prices"
            ? "hover:bg-[#FED7D7]"
            : "hover:bg-gray-100"
        }`}
      >
        {/* {
          <img
            src="/images/jodan.webp"
            alt=""
            className="w-12 h-12 shrink-0 rounded-full object-cover object-center mr-4"
          />
        } */}
        {data.icon && <span className="inline-flex ml-2">{data.icon}</span>}
        <div className="grid auto-rows-auto grid-cols-1 gap-0 w-full">
          <span
            className={`font-medium label ${
              data.label.toLowerCase() === "special prices"
                ? "text-[#F56565] italic"
                : "text-gray"
            }`}
          >
            {data.label}
          </span>
          {data.subLabel && (
            <span
              className={`text-sm text-black/50 !leading-tight lg:max-w-[98%] ${
                data.label.toLowerCase() === "special prices"
                  ? "italic"
                  : "normal"
              }`}
            >
              {data.subLabel}
            </span>
          )}
        </div>
        {data.subMenu && (
          <span className="text-sm shrink-0 ml-auto group-hover/list:-translate-x-[0px] -translate-x-[5px] transform transition duration-300 ease">
            <ChevronRight className="transition duration-300 ease-in-out text-gray-700 w-4 h-4" />
          </span>
        )}
      </Link>
      {hasSubMenu && (
        <SubMenu dept={dept} data={data.subMenu} menuIndex={menuIndex} />
      )}
    </li>
  );
};

const SubMenu = ({ dept, data, menuIndex }) => {
  dept = dept + 1;
  return (
    <ul className="px-2 absolute left-full z-0 invisible w-max py-2 bg-white rounded-2xl shadow-menu opacity-0 subMenuChild top-4">
      {data?.map((menu, index) => {
        const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

        return (
          <ListMenu
            dept={dept}
            data={menu}
            hasSubMenu={menu.subMenu}
            menuName={menuName}
            key={menuName}
            menuIndex={index}
          />
        );
      })}
    </ul>
  );
};

export default ListMenu;
