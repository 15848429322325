import { gql } from "./graphql";
export const SUBSCRIBE = gql`
  mutation subscribe($email: String!, $phone: String) {
    createSubscriber(data: { email: $email, phone: $phone }) {
      data {
        attributes {
          email
        }
      }
    }
  }
`;
