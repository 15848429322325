import {
  Item,
  UpdateItemInput,
  addItemWithQuantity,
  removeItemOrQuantity,
  addItem,
  updateItem,
  removeItem,
  calculateUniqueItems,
  calculateItemTotals,
  calculateTotalItems,
  calculateTotal,
} from "./cart.utils";

export const initialState = {
  items: [],
  isEmpty: true,
  totalItems: 0,
  shippingCost: 0,
  totalUniqueItems: 0,
  total: 0,
  meta: null,
  promoValue: "",
  promoCode: "",
  isNotesChecked: false,
  notes: "",
  giftWrap: false,
};
export function cartReducer(state, action) {
  switch (action.type) {
    case "ADD_ITEM_WITH_QUANTITY": {
      const items = addItemWithQuantity(
        state.items,
        action.item,
        action.quantity
      );
      return generateFinalState(state, items);
    }
    case "REMOVE_ITEM_OR_QUANTITY": {
      const items = removeItemOrQuantity(
        state.items,
        action.id,
        (action.quantity = 1),
        action.size
      );
      return generateFinalState(state, items);
    }
    case "ADD_ITEM": {
      const items = addItem(state.items, action.item);
      return generateFinalState(state, items);
    }
    case "REMOVE_ITEM": {
      const items = removeItem(state.items, action.id, action.size);
      return generateFinalState(state, items);
    }
    case "UPDATE_ITEM": {
      const items = updateItem(state.items, action.id, action.item);
      return generateFinalState(state, items);
    }
    case "UPDATE_SHIPPING_COST": {
      return generateFinalState(
        {
          ...state,
          shippingCost: action.value,
        },
        state.items
      );
    }
    case "UPDATE_PROMO_VALUE": {
      return generateFinalState(
        {
          ...state,
          promoValue: action.value,
          promoCode: action.code,
        },
        state.items
      );
    }
    case "UPDATE_NOTES_VALUE": {
      return generateFinalState(
        {
          ...state,
          notes: action.value,
        },
        state.items
      );
    }
    case "UPDATE_NOTES_CHECKED": {
      return generateFinalState(
        {
          ...state,
          isNotesChecked: action.value,
        },
        state.items
      );
    }
    case "UPDATE_GIFT_WRAP": {
      return generateFinalState(
        {
          ...state,
          giftWrap: action.value,
        },
        state.items
      );
    }
    case "RESET_CART":
      return initialState;
    default:
      return state;
  }
}

const generateFinalState = (state, items) => {
  const totalUniqueItems = calculateUniqueItems(items);
  return {
    ...state,
    items: calculateItemTotals(items),
    totalItems: calculateTotalItems(items),
    totalUniqueItems,
    total: calculateTotal(items),
    isEmpty: totalUniqueItems === 0,
  };
};
