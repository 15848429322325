import { gql } from "./graphql";
export const QUERY_ALL_PRODUCT_CATEGORIES = gql`
  query getProductCategories {
    productCategories(
      sort: "order:asc"
      pagination: { limit: 15 }
      filters: { order: { gt: 0 } }
    ) {
      data {
        id
        attributes {
          name
          slug
          order
          image {
            data {
              attributes {
                alternativeText
                url
                formats
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_ALL_PRODUCT_CATEGORIES_BY_COLLECTION = gql`
  query getProductCategoriesByCollection(
    $limit: Int!
    $filter: ProductCategoryFiltersInput
    $sortBy: [String]
    $slug: String
  ) {
    productCategories(
      sort: $sortBy
      pagination: { limit: $limit }
      filters: $filter
    ) {
      data {
        id
        attributes {
          name
          image {
            data {
              attributes {
                alternativeText
                url
              }
            }
          }
          products(
            pagination: { limit: $limit }
            filters: { productCollections: { slug: { eq: $slug } } }
          ) {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;
