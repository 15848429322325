import { gql } from "./graphql";
export const All_TESTIMONIALS = gql`
  query testimonials {
    testimonials {
      data {
        attributes {
          customerName
          business
          review
        }
      }
    }
  }
`;
export const All_CELEBS = gql`
  query gallery {
    celebsGallery {
      data {
        attributes {
          gallery {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
export const All_CUSTOMERS_GALLERY = gql`
  query gallery {
    customerGallery {
      data {
        attributes {
          gallery {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
export const STEPS = gql`
  query steps {
    stepAuthentication {
      data {
        attributes {
          banner {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          heading
          steps {
            description
            title
            icon {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const INSTAGRAM_CAROUSEL = gql`
  query instagram {
    instagramCarousel {
      data {
        attributes {
          Carousel {
            id
            link
            image {
              data {
                attributes {
                  url
                  alternativeText
                  formats
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const TIMER = gql`
  query timer {
    timer {
      data {
        attributes {
          title
          buttonText
          buttonLink
          expiry
        }
      }
    }
  }
`;
