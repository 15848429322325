import { createClient, cacheExchange, fetchExchange } from "@urql/core";
import { useSession } from "next-auth/react";
import * as React from "react";

/**
 * Get GraphQL Client in browser environments (frontend).
 *
 * If the user has an active session, it will add an accessToken to all requests
 */
const useClient = (options) => {
  const { data: session } = useSession();
  const token = session?.jwt;
  // const handleError = useErrorHandler();

  return React.useMemo(() => {
    const client = createClient({
      url: `${process.env.NEXT_PUBLIC_STRAPI_URL}/graphql`,
      exchanges: [cacheExchange, fetchExchange],
      fetchOptions: () => {
        return {
          headers: {
            authorization: token ? `Bearer ${token}` : "",
            ...(options?.headers ? options.headers : {}),
          },
        };
      },
    });

    return client;
  }, [options, token]);
};

export default useClient;
